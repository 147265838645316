export const VISIBLE_INTEGRATIONS = [
    {
        'group': 'Marketplaces',
        'name': 'Ubereats',
        'oid': 14,
        "extra_fields": ["app_username", "app_password", "password", "pin"],
        'type': 0
    },
    {
        'group': 'Marketplaces',
        'name': 'Grubhub',
        'oid': 3,
        "extra_fields": ["password"],
        'type': 0
    },
    {
        'group': 'Marketplaces',
        'name': 'Doordash',
        'oid': 17,
        "extra_fields": ["app_username", "app_password", "password"],
        'type': 0
    },
    {
        'group': 'Marketplaces',
        'name': 'Grabull',
        'oid': 32,
        'type': 1,
        "disabled": true
    },
    {
        'group': 'Marketplaces',
        'name': 'Slice',
        'oid': 10,
        "extra_fields": ["password"],
        'type': 0
    },
    {
        'group': 'Marketplaces',
        'name': 'RicePo',
        'oid': 27,
        "extra_fields": ["password"],
        'type': 0
    },
    {
        'group': 'Marketplaces',
        'name': 'SnackPass',
        "disabled": true,
        'oid': 12,
        'type': 1
    },
    {
        'group': 'Marketplaces',
        'name': 'EatStreet',
        'oid': 12,
        'type': 1,
        // "disabled":true,
    },
    {
        'group': 'Marketplaces',
        'name': 'Delivery',
        'oid': 7,
        'type': 1,
        // "disabled":true,
    },
    {
        'group': 'Order Direct',
        'name': 'Chownow',
        'oid': 11,
        'type': 1,
        // "disabled":true,
    },
    {
        'group': 'Order Direct',
        'name': 'Toast',
        'oid': 39,
        'type': 1,
        // "disabled":true,
    },
    {
        'group': 'Order Direct',
        'name': 'Gloria',
        'oid': 25,
        'type': 0,
        // "disabled":true,
    },
    {
        'group': 'Order Direct',
        'name': 'Menufy',
        'oid': 33,
        "extra_fields": [],
        'type': 0,
        // "disabled":true,
    },
    {
        'group': 'Order Direct',
        'name': 'BeyondMenu',
        'oid': 9,
        'type': 1,
        // "disabled":true,
    },
    {
        'group': 'Order Direct',
        'name': 'Edining',
        'oid': 22,
        'type': 1,
        // "disabled":true,
    },
    {
        'group': 'Order Direct',
        'name': 'Wix',
        'oid': 26,
        'type': 1,
        "disabled": true,
    },
    {
        'group': 'Order Direct',
        'name': 'Qmenu',
        'oid': 36,
        'type': 1,
        "disabled": true,
    },
    {
        'group': 'Order Direct',
        'name': 'Popmenu',
        'oid': 37,
        'type': 1,
        "disabled": true,
    },
    {
        'group': 'Catering',
        'name': 'EZcater',
        'oid': 16,
        'type': 0,
        "disabled": true,
    },
    // {
    //     'group': 'Catering',
    //     'name': 'Grubhub for work',
    //     'oid': 23,
    //     'type': 0,
    //     "disabled": true,
    // }
];


export const integrations = [{
    text: 'Ubereats',
    value: 'AF',
    image: 'UberEats'
}, {
    text: 'Grubhub',
    value: '2',
    image: 'GrubHub'
}, {
    text: 'Doordash',
    value: '3',
    image: 'DoorDash'
}, {
    text: 'Chownow',
    value: '4',
    image: 'Chownow'
}, {
    text: 'Grabull',
    value: '5',
    image: 'Grabull'
}, {
    text: 'Slice',
    value: '6',
    image: 'SliceLife'
}, {
    text: 'Beyoundmenu',
    value: '7',
    image: 'BeyoundMenu'
}, {
    text: 'Snackpass',
    value: '8',
    image: 'SnackPass'
}, {
    text: 'Ricepo',
    value: '9',
    image: 'RicePo'
}, {
    text: 'Menufi',
    value: '10',
    image: 'MenuFi'
}, {
    text: 'eatstreet',
    value: '11',
    image: 'EatStreet'
}, {
    text: 'delivery.com',
    value: '12',
    image: 'Delivery-com'
}, {
    text: 'Ritual',
    value: '13',
    image: 'Ritual'
}, {
    text: 'Gloria',
    value: '14',
    image: 'GloriaFood'
}, {
    text: 'Toast',
    value: '15',
    image: 'ToastPOS'
}, {
    text: 'Wix',
    value: '16',
    image: 'Wix'
}, {
    text: 'Popmenu',
    value: '17',
    image: 'PopMenu'
}, {
    text: 'Qmenu',
    value: '18',
    image: 'QMenu'
}, {
    text: 'Edinningexpress',
    value: '19',
    image: 'edinning'
}, {
    text: 'Ezcater',
    value: '20',
    image: 'ezcater'
}];

export const brands = [
    {
        text: 'Ubereats',
        value: 'AF',
        image: 'ubereatslogo'
    }, {
        text: 'Grubhub',
        value: '2',
        image: 'grubhublogo'
    }, {
        text: 'Doordash',
        value: '3',
        image: 'doordashlogo'
    }, {
        text: 'Chownow',
        value: '4',
        image: 'ChowNow'
    }, {
        text: 'Grabull',
        value: '5',
        image: 'Grabull'
    }, {
        text: 'Slice',
        value: '6',
        image: 'Slice'
    }, {
        text: 'Beyoundmenu',
        value: '7',
        image: 'BeyoundMenu'
    }, {
        text: 'Snackpass',
        value: '8',
        image: 'SnackPass'
    }, {
        text: 'Ricepo',
        value: '9',
        image: 'RicePo'
    }, {
        text: 'Menufi',
        value: '10',
        image: 'MenuFi'
    }, {
        text: 'eatstreet',
        value: '11',
        image: 'AF'
    }, {
        text: 'delivery.com',
        value: '12',
        image: 'AF'
    }, {
        text: 'Ritual',
        value: '13',
        image: 'AF'
    }, {
        text: 'Gloria',
        value: '14',
        image: 'AF'
    }, {
        text: 'Toast',
        value: '15',
        image: 'Toast'
    }, {
        text: 'Wix',
        value: '16',
        image: 'AD'
    }, {
        text: 'Popmenu',
        value: '17',
        image: 'Postmates'
    }, {
        text: 'Qmenu',
        value: '18',
        image: 'AD'
    }, {
        text: 'Edinningexpress',
        value: '19',
        image: 'edining'
    }, {
        text: 'Ezcater',
        value: '20',
        image: 'Ezcater'
    }];

export const locationList = [{
    text: 'Add Location',
    value: '1',
    image: 'default'
}, {
    text: 'Ubereats',
    value: 'AF',
    image: 'ubereatslogo'
}, {
    text: 'Grubhub',
    value: '2',
    image: 'grubhublogo'
}, {
    text: 'Doordash',
    value: '3',
    image: 'doordashlogo'
}, {
    text: 'Chownow',
    value: '4',
    image: 'ChowNow'
}, {
    text: 'Grabull',
    value: '5',
    image: 'Grabull'
}, {
    text: 'Slice',
    value: '6',
    image: 'Slice'
}, {
    text: 'Beyoundmenu',
    value: '7',
    image: 'BeyoundMenu'
}, {
    text: 'Snackpass',
    value: '8',
    image: 'SnackPass'
}, {
    text: 'Ricepo',
    value: '9',
    image: 'RicePo'
}, {
    text: 'Menufi',
    value: '10',
    image: 'MenuFi'
}, {
    text: 'eatstreet',
    value: '11',
    image: 'AF'
}, {
    text: 'delivery.com',
    value: '12',
    image: 'AF'
}, {
    text: 'Ritual',
    value: '13',
    image: 'AF'
}, {
    text: 'Gloria',
    value: '14',
    image: 'AF'
}, {
    text: 'Toast',
    value: '15',
    image: 'Toast'
}, {
    text: 'Wix',
    value: '16',
    image: 'AD'
}, {
    text: 'Popmenu',
    value: '17',
    image: 'Postmates'
}, {
    text: 'Qmenu',
    value: '18',
    image: 'AD'
}, {
    text: 'Edinningexpress',
    value: '19',
    image: 'edining'
}, {
    text: 'Ezcater',
    value: '20',
    image: 'Ezcater'
}];


export const brandData = [{
    text: 'Ubereats',
    value: 'AF',
    image: 'ubereatslogo'
}, {
    text: 'Grubhub',
    value: '2',
    image: 'grubhublogo'
}, {
    text: 'Doordash',
    value: '3',
    image: 'doordashlogo'
}, {
    text: 'Chownow',
    value: '4',
    image: 'ChowNow'
}, {
    text: 'Grabull',
    value: '5',
    image: 'Grabull'
}, {
    text: 'Slice',
    value: '6',
    image: 'Slice'
}, {
    text: 'Beyoundmenu',
    value: '7',
    image: 'BeyoundMenu'
}, {
    text: 'Snackpass',
    value: '8',
    image: 'SnackPass'
}, {
    text: 'Ricepo',
    value: '9',
    image: 'RicePo'
}, {
    text: 'Menufi',
    value: '10',
    image: 'MenuFi'
}, {
    text: 'eatstreet',
    value: '11',
    image: 'AF'
}, {
    text: 'delivery.com',
    value: '12',
    image: 'AF'
}, {
    text: 'Ritual',
    value: '13',
    image: 'AF'
}, {
    text: 'Gloria',
    value: '14',
    image: 'AF'
}, {
    text: 'Toast',
    value: '15',
    image: 'Toast'
}, {
    text: 'Wix',
    value: '16',
    image: 'AD'
}, {
    text: 'Popmenu',
    value: '17',
    image: 'Postmates'
}, {
    text: 'Qmenu',
    value: '18',
    image: 'AD'
}, {
    text: 'Edinningexpress',
    value: '19',
    image: 'edining'
}, {
    text: 'Ezcater',
    value: '20',
    image: 'Ezcater'
}];
export const locationData = [{
    text: 'Ubereats',
    value: 'AF',
    image: 'ubereatslogo'
}, {
    text: 'Grubhub',
    value: '2',
    image: 'grubhublogo'
}, {
    text: 'Doordash',
    value: '3',
    image: 'doordashlogo'
}, {
    text: 'Chownow',
    value: '4',
    image: 'ChowNow'
}, {
    text: 'Grabull',
    value: '5',
    image: 'Grabull'
}, {
    text: 'Slice',
    value: '6',
    image: 'Slice'
}, {
    text: 'Beyoundmenu',
    value: '7',
    image: 'BeyoundMenu'
}, {
    text: 'Snackpass',
    value: '8',
    image: 'SnackPass'
}, {
    text: 'Ricepo',
    value: '9',
    image: 'RicePo'
}, {
    text: 'Menufi',
    value: '10',
    image: 'MenuFi'
}, {
    text: 'eatstreet',
    value: '11',
    image: 'AF'
}, {
    text: 'delivery.com',
    value: '12',
    image: 'AF'
}, {
    text: 'Ritual',
    value: '13',
    image: 'AF'
}, {
    text: 'Gloria',
    value: '14',
    image: 'AF'
}, {
    text: 'Toast',
    value: '15',
    image: 'Toast'
}, {
    text: 'Wix',
    value: '16',
    image: 'AD'
}, {
    text: 'Popmenu',
    value: '17',
    image: 'Postmates'
}, {
    text: 'Qmenu',
    value: '18',
    image: 'AD'
}, {
    text: 'Edinningexpress',
    value: '19',
    image: 'edining'
}, {
    text: 'Ezcater',
    value: '20',
    image: 'Ezcater'
}];



export const cuisineType = [{
    "text": "Afghan",
    "value": "1",
    "avatar": "m1"
}, {
    "text": "African",
    "value": "2",
    "avatar": "f1"
}, {
    "text": "Albanian",
    "value": "3",
    "avatar": "m2"
}, {
    "text": "American",
    "value": "4",
    "avatar": "f2"
}, {
    "text": "Arabian",
    "value": "5",
    "avatar": "f3"
}, {
    "text": "Argentinian",
    "value": "6",
    "avatar": "f4"
}, {
    "text": "Asian Fusion",
    "value": "7",
    "avatar": "m3"
}, {
    "text": "Australian",
    "value": "8",
    "avatar": "m4"
}, {
    "text": "Austrian",
    "value": "9",
    "avatar": "m4"
}, {
    "text": "Bagels",
    "value": "10",
    "avatar": "m4"
}, {
    "text": "Bakery",
    "value": "11",
    "avatar": "m4"
}
    , {
    "text": "Bangladeshi",
    "value": "12",
    "avatar": "m4"
}, {
    "text": "Barbeque",
    "value": "13",
    "avatar": "m4"
}, {
    "text": "Belgian",
    "value": "14",
    "avatar": "m4"
}, {
    "text": "Brasseries",
    "value": "15",
    "avatar": "m4"
}, {
    "text": "Brazilian",
    "value": "16",
    "avatar": "m4"
}, {
    "text": "Breakfast",
    "value": "17",
    "avatar": "m4"
}, {
    "text": "British",
    "value": "18",
    "avatar": "m4"
}, {
    "text": "Brunch",
    "value": "19",
    "avatar": "m4"
}, {
    "text": "Buffets",
    "value": "20",
    "avatar": "m4"
}, {
    "text": "Burgers",
    "value": "21",
    "avatar": "m4"
}, {
    "text": "Burmese",
    "value": "22",
    "avatar": "m4"
}, {
    "text": "Cafes",
    "value": "23",
    "avatar": "m4"
}, {
    "text": "Cafeteria",
    "value": "24",
    "avatar": "m4"
}, {
    "text": "Cajun",
    "value": "25",
    "avatar": "m4"
}, {
    "text": "Californian",
    "value": "26",
    "avatar": "m4"
}, {
    "text": "Calzones",
    "value": "27",
    "avatar": "m4"
}, {
    "text": "Cambodian",
    "value": "28",
    "avatar": "m4"
}, {
    "text": "Cantonese",
    "value": "29",
    "avatar": "m4"
}, {
    "text": "Caribbean",
    "value": "30",
    "avatar": "m4"
}, {
    "text": "Catalan",
    "value": "31",
    "avatar": "m4"
}, {
    "text": "Cheesesteaks",
    "value": "32",
    "avatar": "m4"
}, {
    "text": "Chicken",
    "value": "33",
    "avatar": "m4"
}, {
    "text": "Chicken Wings",
    "value": "34",
    "avatar": "m4"
}, {
    "text": "Chili",
    "value": "35",
    "avatar": "m4"
}, {
    "text": "Chinese",
    "value": "36",
    "avatar": "m4"
}, {
    "text": "Classic",
    "value": "37",
    "avatar": "m4"
}, {
    "text": "Coffee and Tea",
    "value": "38",
    "avatar": "m4"
}, {
    "text": "Colombian",
    "value": "39",
    "avatar": "m4"
}, {
    "text": "Comfort Food",
    "value": "40",
    "avatar": "m4"
}, {
    "text": "Costa Rican",
    "value": "41",
    "avatar": "m4"
}, {
    "text": "Creole",
    "value": "42",
    "avatar": "m4"
}, {
    "text": "Crepes",
    "value": "43",
    "avatar": "m4"
}, {
    "text": "Cuban",
    "value": "44",
    "avatar": "m4"
}, {
    "text": "Czech ",
    "value": "45",
    "avatar": "m4"
},
{
    "text": "Delis ",
    "value": "46",
    "avatar": "m4"
},
{
    "text": "Dessert ",
    "value": "47",
    "avatar": "m4"
},
{
    "text": "Dim Sum ",
    "value": "48",
    "avatar": "m4"
},
{
    "text": "Diner ",
    "value": "49",
    "avatar": "m4"
},
{
    "text": "Dominican ",
    "value": "50",
    "avatar": "m4"
},
{
    "text": "Eclectic Ecuadorian ",
    "value": "51",
    "avatar": "m4"
},
{
    "text": "Egyptian ",
    "value": "52",
    "avatar": "m4"
},
{
    "text": "El Salvadoran",
    "value": "53",
    "avatar": "m4"
}, {
    "text": "Empanadas  "
    , "value": "54"
    , "avatar": "m4"
}, { "text": "English", "value": "55", "avatar": "m4" },
{ "text": "Ethiopian", "value": "56", "avatar": "m4" },
{ "text": "Fast Food", "value": "57", "avatar": "m4" },
{ "text": "Filipino", "value": "58", "avatar": "m4" },
{ "text": "Fine Dining", "value": "59", "avatar": "m4" },
{ "text": "Fish & Chips", "value": "60", "avatar": "m4" },
{ "text": "Fondue", "value": "11", "avatar": "m4" },
{ "text": "Food Cart", "value": "61", "avatar": "m4" },
{ "text": "Food Court", "value": "62", "avatar": "m4" },
{ "text": "Food Stands", "value": "63", "avatar": "m4" },
{ "text": "French", "value": "64", "avatar": "m4" },
{ "text": "Fresh Fruits", "value": "65", "avatar": "m4" },
{ "text": "Frozen Yogurt", "value": "66", "avatar": "m4" },
{ "text": "Gastropubs", "value": "67", "avatar": "m4" },
{ "text": "German", "value": "68", "avatar": "m4" },
{ "text": "Gluten-Free", "value": "69", "avatar": "m4" },
{ "text": "Greek", "value": "70", "avatar": "m4" },
{ "text": "Grill", "value": "71", "avatar": "m4" },
{ "text": "Guatemalan Gyro", "value": "11", "avatar": "m4" },
{ "text": "Haitian", "value": "72", "avatar": "m4" },
{ "text": "Halal", "value": "73", "avatar": "m4" },
{ "text": "Hawaiian", "value": "74", "avatar": "m4" },
{ "text": "Himalayan", "value": "75", "avatar": "m4" },
{ "text": "Hoagies", "value": "76", "avatar": "m4" },
{ "text": "Hot Dogs", "value": "77", "avatar": "m4" },
{ "text": "Hot Pot", "value": "78", "avatar": "m4" },
{ "text": "Hungarian", "value": "79", "avatar": "m4" },
{ "text": "Iberian", "value": "80", "avatar": "m4" },
{ "text": "Ice Cream", "value": "81", "avatar": "m4" },
{ "text": "Indian", "value": "82", "avatar": "m4" },
{ "text": "Indonesian", "value": "83", "avatar": "m4" },
{ "text": "Irish", "value": "84", "avatar": "m4" },
{ "text": "Italian", "value": "85", "avatar": "m4" },
{ "text": "Jamaican", "value": "86", "avatar": "m4" },
{ "text": "Japanese", "value": "87", "avatar": "m4" },
{ "text": "Kids", "value": "88", "avatar": "m4" },
{ "text": "Korean", "value": "89", "avatar": "m4" },
{ "text": "Kosher", "value": "90", "avatar": "m4" },
{ "text": "Laotian", "value": "91", "avatar": "m4" },
{ "text": "Late Night", "value": "92", "avatar": "m4" },
{ "text": "Latin", "value": "93", "avatar": "m4" },
{ "text": "American", "value": "94", "avatar": "m4" },
{ "text": "Lebanese", "value": "95", "avatar": "m4" },
{ "text": "Live/Raw Food", "value": "96", "avatar": "m4" },
{ "text": "Low Carb Malaysian ", "value": "97", "avatar": "m4" },
{ "text": "Mandarin", "value": "98", "avatar": "m4" },
{ "text": "Mediterranean", "value": "99", "avatar": "m4" },
{ "text": "Mexican", "value": "100", "avatar": "m4" },
{ "text": "Middle Eastern", "value": "101", "avatar": "m4" },
{ "text": "Modern European", "value": "102", "avatar": "m4" },
{ "text": "Mongolian", "value": "103", "avatar": "m4" },
{ "text": "Moroccan", "value": "104", "avatar": "m4" }, { "text": "Nepalese ", "value": "11", "avatar": "m4" },
{ "text": "Noodles Nouvelle cuisin", "value": "11", "avatar": "m4" },
{ "text": "Nutritious ", "value": "105", "avatar": "m4" },
{ "text": "Organic ", "value": "106", "avatar": "m4" },
{ "text": "Pakistani", "value": "107", "avatar": "m4" },
{ "text": "Pancakes ", "value": "108", "avatar": "m4" },
{ "text": "Pasta ", "value": "109", "avatar": "m4" },
{ "text": "Persian/Iranian ", "value": "110", "avatar": "m4" },
{ "text": "Peruvian ", "value": "111", "avatar": "m4" },
{ "text": "Pitas ", "value": "123", "avatar": "m4" },
{ "text": "Pizza ", "value": "124", "avatar": "m4" },
{ "text": "Polish Portuguese ", "value": "125", "avatar": "m4" },
{ "text": "Potato ", "value": "126", "avatar": "m4" },
{ "text": "Poutineries ", "value": "127", "avatar": "m4" },
{ "text": "Pub Food ", "value": "128", "avatar": "m4" },
{ "text": "Puerto Rican ", "value": "129", "avatar": "m4" },
{ "text": "Ribs ", "value": "130", "avatar": "m4" },
{ "text": "Russian ", "value": "131", "avatar": "m4" },
{ "text": "Salad ", "value": "132", "avatar": "m4" },
{ "text": "Sandwiches ", "value": "133", "avatar": "m4" },
{ "text": "Scandinavian ", "value": "134", "avatar": "m4" },
{ "text": "Scottish ", "value": "135", "avatar": "m4" },
{ "text": "Seafood Senegalese ", "value": "136", "avatar": "m4" },
{ "text": "Singaporean ", "value": "137", "avatar": "m4" },
{ "text": "Slovakian ", "value": "138", "avatar": "m4" },
{ "text": "Small Plates ", "value": "139", "avatar": "m4" },
{ "text": "Smoothies and Juices ", "value": "140", "avatar": "m4" },
{ "text": "Soul Food ", "value": "141", "avatar": "m4" },
{ "text": "Soup ", "value": "142", "avatar": "m4" },
{ "text": "South African ", "value": "143", "avatar": "m4" },
{ "text": "South American Southern", "value": "144", "avatar": "m4" },
{ "text": "Southwestern ", "value": "145", "avatar": "m4" },
{ "text": "Spanish ", "value": "146", "avatar": "m4" },
{ "text": "Sri Lankan ", "value": "147", "avatar": "m4" },
{ "text": "Steakhouses ", "value": "148", "avatar": "m4" },
{ "text": "Subs ", "value": "149", "avatar": "m4" },
{ "text": "Supper Clubs ", "value": "150", "avatar": "m4" },
{ "text": "Sushi Bars ", "value": "151", "avatar": "m4" },
{ "text": "Syrian ", "value": "152", "avatar": "m4" },
{ "text": "Szechwan ", "value": "153", "avatar": "m4" },
{ "text": "Taiwanese Tapas ", "value": "154", "avatar": "m4" },
{ "text": "Tex-Mex ", "value": "155", "avatar": "m4" },
{ "text": "Thai ", "value": "156", "avatar": "m4" },
{ "text": "Tibetan ", "value": "157", "avatar": "m4" },
{ "text": "Turkish ", "value": "158", "avatar": "m4" },
{ "text": "Ukrainian", "value": "159", "avatar": "m4" },
{ "text": "Uzbek ", "value": "160", "avatar": "m4" },
{ "text": "Vegan", "value": "161", "avatar": "m4" },
{ "text": "Vegetarian", "value": "162", "avatar": "m4" },
{ "text": "Vietnamese ", "value": "163", "avatar": "m4" },
{ "text": "Wraps", "value": "164", "avatar": "m4" }
];

export const userList = [{
    text: 'Ahmed - ahmed@gmail.com',
    value: '1',
}, {
    text: 'John - john@gmail.com',
    value: '2',
}, {
    text: 'Devid - devid@gmail.com',
    value: '3',
}, {
    text: 'Ahmed1 - ahmed@gmail.com',
    value: '4',
}, {
    text: 'John1 - john@gmail.com',
    value: '5',
}, {
    text: 'Devid1 - devid@gmail.com',
    value: '6',
}];

export const locations = [{
    text: 'location 1',
    value: '1',
}, {
    text: 'location 2',
    value: '2',
}, {
    text: 'location 3',
    value: '3',
}, {
    text: 'location 4',
    value: '4',
}, {
    text: 'location 5',
    value: '5',
}, {
    text: 'location 6',
    value: '6',
}];

export const statusType = [{
    text: 'Error 1',
    value: '1',
}, {
    text: 'Disputed 2',
    value: '2',
}, {
    text: 'Accepted 3',
    value: '3',
}];




export const marketplace = [{
    text: 'marketplace 1',
    value: '1',
}, {
    text: 'marketplace 2',
    value: '2',
}, {
    text: 'marketplace 3',
    value: '3',
}, {
    text: 'marketplace 4',
    value: '4',
}, {
    text: 'marketplace 5',
    value: '5',
}, {
    text: 'marketplace 6',
    value: '6',
}];

export const paymentType = [{
    text: 'paymentType 1',
    value: '1',
}, {
    text: 'paymentType 2',
    value: '2',
}, {
    text: 'paymentType 3',
    value: '3',
}, {
    text: 'paymentType 4',
    value: '4',
}, {
    text: 'paymentType 5',
    value: '5',
}, {
    text: 'paymentType 6',
    value: '6',
}];

export const fulfillmentType = [{
    text: 'fulfillmentType 1',
    value: '1',
}, {
    text: 'fulfillmentType 2',
    value: '2',
}, {
    text: 'fulfillmentType 3',
    value: '3',
}, {
    text: 'fulfillmentType 4',
    value: '4',
}, {
    text: 'fulfillmentType 5',
    value: '5',
}, {
    text: 'fulfillmentType 6',
    value: '6',
}];




export const UserTypeMap = {
    "1": { id: "1", name: 'Restaurant Owner' },
    "2": { id: "2", name: 'Virtual Kitchens' },
    "4": { id: "4", name: 'Reseller' },
};

export const RoleTypeMap = {
    "1": { id: "1", name: 'General Manager' },
    "2": { id: "2", name: 'Operation Manager' },
    "3": { id: "3", name: 'Accountant' },
    "4": { id: "4", name: 'Employee' },
};
export const UserSubStatusMap = {
    0: {
        text: 'Active User',
        status: 'Active'
    },
    1: {
        text: 'No Activity',
        status: 'Active'
    },
    2: {
        text: 'In-Complete',
        status: 'Attention'
    },
    3: {
        text: 'Free trial',
        status: 'Attention'
    },
    4: {
        text: 'Payment Method Issues',
        status: 'Attention'
    },
    5: {
        text: 'Billing Issues',
        status: 'Attention'
    },
    6: {
        text: 'Deactivated',
        status: 'InActive'
    },
    7: {
        text: 'Billing Issues',
        status: 'InActive'
    }
}
export const UserStatusLabelMap = {
    "Active": {
        text: 'Active',
        textClass: 'text-green',
        bgClass: 'bg-green-light',
        previewClass: 'bg-green'
    },
    "InActive": {
        text: 'InActive',
        textClass: 'text-red',
        bgClass: 'bg-red-light',
        previewClass: 'bg-cyan'
    }, "Attention": {
        text: 'Attention',
        textClass: 'text-orange',
        bgClass: 'bg-orange-light',
        previewClass: 'bg-cyan'
    },
};
export const LocationSubStatusMap = {
  0: {
      text: 'Active',
      status: 'Active'
  },
  1: {
      text: 'Orders Paused',
      status: 'Attention'
  },
  2: {
      text: 'In-Complete',
      status: 'Attention'
  },
  3: {
      text: 'No Activity',
      status: 'Attention'
  },
  4: {
      text: 'Payment Method Issues',
      status: 'Attention'
  },
  5: {
      text: 'Billing Issues',
      status: 'Attention'
  },
  6: {
      text: 'Deactivated',
      status: 'InActive'
  },
  7: {
      text: 'Billing Issues',
      status: 'InActive'
  }
}
export const IntegrationGroupByList = [
    { id: 0, name: 'Source', selected: true },
    { id: 1, name: 'Location' },
    { id: 2, name: 'Brand' },
    { id: 7, name: 'User' },
    { id: 8, name: 'Status' }
];
export const IntegrationFilterStatusList = [
    { id: 1, name: 'Active', selected: true },
    { id: 8, name: 'Needs Attention' },
    { id: 8, name: 'In-active' }
];
export const OrderDeliveryTypeMap = {
    1: 'Pickup',
    2: 'Delivery',
    3: 'Carrier',
    4: 'Dine-in',
    5: 'Drive-thru'
}


export const OrderProgressStatusLabelMap = {
    '0': {
        id: '0',
        text: 'Pending',
        textClass: 'text-black',
        bgClass: 'bg-green-light',
        previewClass: 'bg-green',
        headerClass: 'pending_status',
        footerClass: 'pending_status',
    },
    '1': {
        id: '1',
        text: 'Active',
        textClass: 'text-orange',
        bgClass: 'bg-orange-light',
        previewClass: 'bg-cyan',
        headerClass: 'completed_status',
        footerClass: 'completed_status',
    },
    '3': {
        id: '3',
        text: 'Failed',
        textClass: 'text-red',
        bgClass: 'bg-red-light',
        previewClass: 'bg-cyan',
        headerClass: 'failed_status',
        footerClass: 'failed_status',
    },
    '2': {
        id: '2',
        text: 'Completed',
        textClass: 'text-green',
        bgClass: 'bg-green-light',
        previewClass: 'bg-green',
        headerClass: 'active_status',
        footerClass: 'active_status',
    },
    '4': {
        id: '4',
        text: 'Future',
        textClass: 'text-bar',
        bgClass: 'bg-app-bar',
        previewClass: 'bg-cyan',
        headerClass: 'future_status',
        footerClass: 'future',
    },
    '5': {
        id: '5',
        text: 'For Later',
        textClass: 'text-bar',
        bgClass: 'bg-app-bar',
        previewClass: 'bg-cyan',
        headerClass: 'future_status',
        footerClass: 'future',
    },
};


export const IntegrationStatusLabelMap = {
    0: {
        text: "Closed for Orders",
        textClass: 'text-green',
        bgClass: 'bg-green-light',
        previewClass: 'bg-green'
    },
    1: {
        text: "Accepting Orders",
        textClass: 'text-green',
        bgClass: 'bg-green-light',
        previewClass: 'bg-green'
    },
    2: {
        text: "Paused Orders by Restaurant",
        textClass: 'text-orange',
        bgClass: 'bg-orange-light',
        previewClass: 'bg-cyan'
    },
    3: {
        text: "Paused Orders by Partner",
        textClass: 'text-orange',
        bgClass: 'bg-orange-light',
        previewClass: 'bg-cyan'
    },
    4: {
        text: "Dead Integration",
        textClass: 'text-red',
        bgClass: 'bg-red-light',
        previewClass: 'bg-cyan'
    },
    5: {
        text: 'Wrong credentials',
        textClass: 'text-red',
        bgClass: 'bg-red-light',
        previewClass: 'bg-cyan'
    },
    6: {
        text: 'Deactivated',
        textClass: 'text-red',
        bgClass: 'bg-red-light',
        previewClass: 'bg-cyan'
    },
    7: {
        text: 'Connecting',
        textClass: 'text-orange',
        bgClass: 'bg-orange-light',
        previewClass: 'bg-cyan'
    },
    8: {
        text: 'Restarting',
        textClass: 'text-orange',
        bgClass: 'bg-orange-light',
        previewClass: 'bg-cyan'
    },
}
export const StatusLabelMap = {
    "Active": {
        text: 'Active',
        textClass: 'text-green',
        bgClass: 'bg-green-light',
        previewClass: 'bg-green'
    },
    "InActive": {
        text: 'InActive',
        textClass: 'text-red',
        bgClass: 'bg-red-light',
        previewClass: 'bg-cyan'
    }, "Attention": {
        text: 'Attention',
        textClass: 'text-orange',
        bgClass: 'bg-orange-light',
        previewClass: 'bg-cyan'
    },
};
export const OrdeTypeList = [

    { id: '1', name: 'Pickup' },
    { id: '2', name: 'Delivery' },
]
export const FulfillmentTypeList = [{
  text: 'Pickup',
  value: 1,
}, {
  text: 'Delivery',
  value: 2,
}, {
  text: 'Dine In',
  value: 3,
}, {
  text: 'Curbside',
  value: 4,
}]

export const OrderServiceTypeList = [
{
  text: 'Reservation',
  value: 1,
},
{
  text: 'Group Order',
  value: 2,
},
{
  text: 'Catering Order',
  value: 3,
},
{
  text: 'Table Order',
  value: 4,
},
]

export const fulfillment = [{
    text: 'Pickup',
    value: '1',
}, {
    text: 'Delivery',
    value: '2',
}, {
    text: 'Dine In',
    value: '3',
}, {
    text: 'Take Out',
    value: '4',
}, {
    text: 'Catering',
    value: '5',
}, {
    text: 'Curbside',
    value: '6',
}]

export const groups = [{
    text: 'Group 1',
    value: '1',
}, {
    text: 'Group 2',
    value: '2',
}, {
    text: 'Group 3',
    value: '3',
}, {
    text: 'Group 4',
    value: '4',
}, {
    text: 'Group 5',
    value: '5',
}, {
    text: 'Group 6',
    value: '6',
}]

export const modifierGroup = [{
    text: 'Modifier Group 1',
    value: '1',
}, {
    text: 'Modifier Group 2',
    value: '2',
}, {
    text: 'Modifier Group 3',
    value: '3',
}, {
    text: 'Modifier Group 4',
    value: '4',
}, {
    text: 'Modifier Group 5',
    value: '5',
}, {
    text: 'Modifier Group 6',
    value: '6',
}]

export const modifier = [{
    text: 'Modifier 1',
    value: '1',
}, {
    text: 'Modifier 2',
    value: '2',
}, {
    text: 'Modifier 3',
    value: '3',
}, {
    text: 'Modifier 4',
    value: '4',
}, {
    text: 'Modifier 5',
    value: '5',
}, {
    text: 'Modifier 6',
    value: '6',
}]

export const item = [{
    text: 'Item 1',
    value: '1',
}, {
    text: 'Item 2',
    value: '2',
}, {
    text: 'Item 3',
    value: '3',
}, {
    text: 'Item 4',
    value: '4',
}, {
    text: 'Item 5',
    value: '5',
}, {
    text: 'Item 6',
    value: '6',
}]

export const category = [{
    text: 'category 1',
    value: '1',
}, {
    text: 'category 2',
    value: '2',
}, {
    text: 'category 3',
    value: '3',
}, {
    text: 'category 4',
    value: '4',
}, {
    text: 'category 5',
    value: '5',
}, {
    text: 'category 6',
    value: '6',
}]

export const section = [{
    text: 'section 1',
    value: '1',
}, {
    text: 'section 2',
    value: '2',
}, {
    text: 'section 3',
    value: '3',
}, {
    text: 'section 4',
    value: '4',
}, {
    text: 'section 5',
    value: '5',
}, {
    text: 'section 6',
    value: '6',
}]

export const upsell = [{
    text: 'upsell 1',
    value: '1',
}, {
    text: 'upsell 2',
    value: '2',
}, {
    text: 'upsell 3',
    value: '3',
}, {
    text: 'upsell 4',
    value: '4',
}, {
    text: 'upsell 5',
    value: '5',
}, {
    text: 'upsell 6',
    value: '6',
}]




export const template = [{
    text: 'Template 1',
    value: '1',
}, {
    text: 'Template 2',
    value: '2',
}]
export const printsectionname = [{
    text: 'Attach To Item',
    value: '1',
}, {
    text: 'As 1st M.Group',
    value: '2',
},
{
    text: 'As 1st Modifier',
    value: '3',
},
{
    text: 'DO NOT PRINT',
    value: '4',
}]


export const logicModifier = [
    { text: 'Default', group: 'style', value: '1' },
    { text: 'Highlight', group: 'style', value: '2' },
    { text: 'Attach To Item,', group: 'style', value: '3' },
    { text: 'Internal Description', group: 'style', value: '4' },
    { text: 'Print as Item]', group: 'style', value: '5' },
    { text: 'Same Across', group: 'logic', value: '6' },
    { text: 'Template Based', group: 'logic', value: '7' },
];


export const logic = [{
    text: 'Same Across',
    value: '6',
}, {
    text: 'Template Based',
    value: '7',
}]



export const sameacrossmodifier = [{
    text: 'Name', group: 'Print As',
    value: '1',
}, {
    text: 'Description', group: 'Print As',
    value: '2',
}, {
    text: 'Internal Description', group: 'Print As',
    value: '3',
}, {
    text: 'Other', group: 'Print As',
    value: '4',
}, { text: 'Default', group: 'Style', value: '5' },
{ text: 'Highlight', group: 'Style', value: '6' },
{ text: 'Attach To Item,', group: 'Style', value: '7' },
{ text: 'Internal Description', group: 'Style', value: '8' },
{ text: 'Print as Item', group: 'Style', value: '9' },]


export const sameacross = [{
    text: 'Name', group: 'Print As',
    value: '1',
}, {
    text: 'Description', group: 'Print As',
    value: '2',
}, {
    text: 'Internal Description', group: 'Print As',
    value: '3',
}, {
    text: 'Other', group: 'Print As',
    value: '4',
}]

export const templateBased = [
    { text: 'Name', group: 'Template 1', value: '1' },
    { text: 'Description', group: 'Template 1', value: '2' },
    { text: 'Internal name', group: 'Template 1', value: '3' },
    { text: 'Internal Description', group: 'Template 1', value: '4' },
    { text: 'DO NOT PRINT', group: 'Template 1', value: '5' },
    { text: 'Other', group: 'Template 1', value: '6' },
    { text: 'Name', group: 'Template 2', value: '7' },
    { text: 'Description', group: 'Template 2', value: '8' },
    { text: 'Internal name', group: 'Template 2', value: '9' },
    { text: 'Internal Description', group: 'Template 2', value: '10' },
    { text: 'DO NOT PRINT', group: 'Template 2', value: '11' },
    { text: 'Other', group: 'Template 2', value: '12' },
];



export const groupbylist = [{
    text: 'Locations',
    value: '1',
}, {
    text: 'Brands',
    value: '2',
}, {
    text: 'Marketplaces',
    value: '3',
}, {
    text: 'Availability',
    value: '4',
}, {
    text: 'Templates',
    value: '5',
}, {
    text: 'Users',
    value: '6',
}, {
    text: 'Menu',
    value: '7',
}, {
    text: 'Rating',
    value: '8',
}, {
    text: 'Sources',
    value: '9',
}]

export const availabilityList = [{
    text: 'Avaliable',
    value: '1',
}, {
    text: 'Sold Out',
    value: '2',
}, {
    text: 'Out Of Stock',
    value: '3',
}, {
    text: 'Archived',
    value: '4',
}]





