import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UrlService {

  public base:string=environment.api_base;

  public apiBase:string=this.base+"/api";

  public wsBase:string="ws://198.203.31.44:8000/";

  public wsRestaurantNewOrders="ws/restaurant/${restaurant_id}/orders/";

  public wsIntegrationUpdate="ws/restaurant/${restaurant_id}/integrations/";

  // public base:string="http://localhost:8000";
  // public apiBase:string=this.base+"/api";
  public signup:string=this.apiBase+"/user/signup/";

  public forgotPassword:string=this.apiBase+"/user/forgotPassword/";

  public getUser:string=this.apiBase+"/user/getUser/";

  public getStripePaymentMethods:string=this.apiBase+"/user/getStripePaymentMethods/";

  public getStripeClientSecret:string=this.apiBase+"/user/getStripeClientSecret/";


  public removeStripePaymentMethods:string=this.apiBase+"/user/removeStripePaymentMethods/";


  public fileUpload:string=this.apiBase+"/files/upload/";




  public getAbstractLocationsByOwner:string=this.apiBase+"/location/getAbstractLocationsByOwner/";

  public addRestaurant:string=this.apiBase+"/location/addRestaurant/";

  public getLocationDetails:string=this.apiBase+"/location/getLocationDetails/";

  public addShift:string=this.apiBase+"/location/addShift/";

  public addLocationHours:string=this.apiBase+"/location/addHours/";

  public updateLocationHours:string=this.apiBase+"/location/updateHours/";

  public removeLocationHours:string=this.apiBase+"/location/removeHours/";

  public getLocationAllHours:string=this.apiBase+"/location/getAllHours/";

  public removeShift:string=this.apiBase+"/location/removeShift/";

  public getAllSubUsers:string=this.apiBase+"/location/getAllSubUsers/";

  public getRestaurantSettings:string=this.apiBase+"/location/getRestaurantSettings/";

  public updateRestaurantSettings:string=this.apiBase+"/location/updateRestaurantSettings/";

  public enableRestaurant:string=this.apiBase+"/location/enableRestaurant/";

  public updateRestaurant:string=this.apiBase+"/location/updateRestaurant/";

  public disableRestaurant:string=this.apiBase+"/location/disableRestaurant/";

  public getAllLocations:string=this.apiBase+"/location/getAllLocations/";

  public getAllShifts:string=this.apiBase+"/location/getAllShifts/";

  public getAllRestaurantInvoices:string=this.apiBase+"/location/getAllInvoices/";

  public getHours:string=this.apiBase+"/location/getHours/";

  public addRestaurantDevice:string=this.apiBase+"/location/addRestaurantDevice/";

  public removeRestaurantDevice:string=this.apiBase+"/location/removeRestaurantDevice/";

  public getAllMails:string=this.apiBase+"/mail/getAllMails/";

  public getMail:string=this.apiBase+"/mail/getMail/";

  public addImportant:string=this.apiBase+"/mail/addImportant/";

  public removeImportant:string=this.apiBase+"/mail/removeImportant/";

  public getAllMailTags:string=this.apiBase+"/mail/getAllMailTags/";

  public addMailTag:string=this.apiBase+"/mail/addMailTag/";

  public addTag:string=this.apiBase+"/mail/addTag/";

  public removeTag:string=this.apiBase+"/mail/removeTag/";

  public checkMailMap:string=this.apiBase+"/mail/checkMailMap/";

  public addMailMap:string=this.apiBase+"/mail/addMailMap/";







  public getAllProviders:string=this.apiBase+"/provider/getAllProviders/";

  public getProvidersDepositsByQuery:string=this.apiBase+"/provider/getProvidersDepositsByQuery/";

  // public login:string=this.apiBase+"/auth/login";
  public getCreatedUsersSummaryByQuery:string=this.apiBase+"/user/getCreatedUsersSummaryByQuery/";

  public getAllProducts:string=this.apiBase+"/stripe/getAllProducts/";

  public getAllCoupons:string=this.apiBase+"/stripe/getAllCoupons/";

  public confirmAccount:string=this.apiBase+"/user/confirmAccount/";

  public getLocationsByQuery:string=this.apiBase+"/location/getLocationsByQuery/";

  public getAbstractLocationsByQuery:string=this.apiBase+"/location/getAbstractLocationsByQuery/";

  public addUserPlan:string=this.apiBase+"/user_plan/addUserPlan/";

  public updateUserPlan:string=this.apiBase+"/user_plan/updateUserPlan/";

  public getUserPlanByUser:string=this.apiBase+"/user_plan/getUserPlanByUser/";

  public getCreatedUsers:string=this.apiBase+"/user/getCreatedUsers/";


  public addPricingPlan:string=this.apiBase+"/pricing_plan/addPricingPlan/";

  public updatePricingPlan:string=this.apiBase+"/pricing_plan/updatePricingPlan/";


  public addUser:string=this.apiBase+"/user/addUser/";

  public addSubUser:string=this.apiBase+"/user/addSubUser/";

  public updateUser:string=this.apiBase+"/user/updateUser/";

  public disableUser:string=this.apiBase+"/user/disableUser/";

  public enableUser:string=this.apiBase+"/user/enableUser/";

  public getUserSettings:string=this.apiBase+"/user/getUserSettings/";

  public updateUserSettings:string=this.apiBase+"/user/updateUserSettings/";

  public checkUserConfirmation:string=this.apiBase+"/user/checkUserConfirmation/";

  public login:string=this.apiBase+"/user/login/";

  public refreshToken:string=this.apiBase+"/auth/token/";

  public addupdatePasswordUser:string=this.apiBase+"/user/updatePassword/";


  public addBrand:string=this.apiBase+"/brand/addBrand/";

  public getAllBrandMaps:string=this.apiBase+"/brand/getAllBrandMaps/";

  public getAllAbstractBrandMaps:string=this.apiBase+"/brand/getAllAbstractBrandMaps/";

  public getBrandsByQuery:string=this.apiBase+"/brand/getBrandsByQuery/";

  public getAbstractBrandsByQuery:string=this.apiBase+"/brand/getAbstractBrandsByQuery/";

  public getBrandMapsByQuery:string=this.apiBase+"/brand/getBrandMapsByQuery/";

  public getBrandMap:string=this.apiBase+"/brand/getBrandMap/";

  public getBrandSettings:string=this.apiBase+"/brand/getSettings/";

  public updateBrandSettings:string=this.apiBase+"/brand/updateSettings/";

  public updateBrand:string=this.apiBase+"/brand/updateBrand/";

  public activateBrandMap:string=this.apiBase+"/brand/activateBrandMap/";

  public deactivateBrandMap:string=this.apiBase+"/brand/deactivateBrandMap/";

  public removeBrandMap:string=this.apiBase+"/brand/removeBrandMap/";


  public addBrandMapHours:string=this.apiBase+"/brand/addHours/";

  public updateBrandMapHours:string=this.apiBase+"/brand/updateHours/";

  public removeBrandMapHours:string=this.apiBase+"/brand/removeHours/";

  public getAllBrandMapHours:string=this.apiBase+"/brand/getAllHours/";





  public addIntegration:string=this.apiBase+"/integration/addIntegration/";

  public updateOrderServingStatus:string=this.apiBase+"/integration/updateOrderServingStatus/";

  public activateIntegration:string=this.apiBase+"/integration/activateIntegration/";

  public deactivateIntegration:string=this.apiBase+"/integration/deactivateIntegration/";

  public removeIntegration:string=this.apiBase+"/integration/removeIntegration/";

  public getAllIntegrations:string=this.apiBase+"/integration/getAllIntegrations/";

  public getIntegrationsByQuery:string=this.apiBase+"/integration/getIntegrationsByQuery/";

  public getIntegration:string=this.apiBase+"/integration/getIntegration/";

  public updateIntegration:string=this.apiBase+"/integration/updateIntegration/";

  public restartIntegration:string=this.apiBase+"/integration/restartIntegration/";

  public getIntegrationSettings:string=this.apiBase+"/integration/getSettings/";

  public updateIntegrationSettings:string=this.apiBase+"/integration/updateSettings/";

  public addIntegrationHours:string=this.apiBase+"/integration/addHours/";

  public updateIntegrationHours:string=this.apiBase+"/integration/updateHours/";

  public removeIntegrationHours:string=this.apiBase+"/integration/removeHours/";

  public getAllIntegrationHours:string=this.apiBase+"/integration/getAllHours/";



  public getAllInvoices:string= this.apiBase+"/invoice/getAllInvoices/";




  public getOrdersByQuery:string=this.apiBase+"/order/getOrdersByQuery/";

  public addOrderOperations:string=this.apiBase+"/order/addOrderOperations/";

  public updateOrderConfirmation:string=this.apiBase+"/order/updateOrderConfirmation/";

  public getOrders:string=this.apiBase+"/order/getOrders/";

  public getOrderDetails:string=this.apiBase+"/order/getOrderDetails/";

  public getOrderPaymentsByQuery:string=this.apiBase+"/order_payment/getOrderPaymentsByQuery/";

  public getDepositsByQuery:string=this.apiBase+"/deposit/getDepositsByQuery/";

  constructor() { }
}
