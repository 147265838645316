import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, HostListener, Inject, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LayoutService } from '../services/layout.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { checkRouterChildsData } from '../utils/check-router-childs-data';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../config/config.service';

@UntilDestroy()
@Component({
  selector: 'vex-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  windowScrolled: boolean = false
  @Input() sidenavRef: TemplateRef<any>;
  @Input() toolbarRef: TemplateRef<any>;
  @Input() footerRef: TemplateRef<any>;
  @Input() quickpanelRef: TemplateRef<any>;
  @ViewChild('scrollContent', { static: true }) scrollContent: ElementRef;
  isLayoutVertical$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isBoxed$ = this.configService.config$.pipe(map(config => config.boxed));
  isToolbarFixed$ = this.configService.config$.pipe(map(config => config.toolbar.fixed));
  isFooterFixed$ = this.configService.config$.pipe(map(config => config.footer.fixed));
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;
  addClass: any
  removeClass: any
  addRemovePadding: any
  table: any
  scrollDisabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  containerEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.containerEnabled))
  );

  searchOpen$ = this.layoutService.searchOpen$;

  @HostListener('scroll', [''])
  onScroll(): void {
    // alert(1);
  }

  @ViewChild('quickpanel', { static: true }) quickpanel: MatSidenav;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer, { static: true }) sidenavContainer: MatSidenavContainer;
  @ViewChild('search_container', { read: ViewContainerRef }) searchContainer!: ViewContainerRef;

  constructor(private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit() {
    var element1;
    setTimeout(() => {
      element1 = document.getElementById("toolbarnewId");
      this.removeClass = element1
      var element3 = document.getElementById("table-id");
      this.table = element3
    }, 50);
    var element = document.getElementById("scrollContent");
    var element2 = document.getElementById("content-padding");
    this.addRemovePadding = element2
    this.addClass = element
    // element.classList.add("otherclass");
    //hide becasue every time called
    // window.addEventListener('scroll', this.scrollEvent, true);
    /**
     * Expand Sidenav when we switch from mobile to desktop view
     */
    this.isDesktop$.pipe(
      filter(matches => !matches),
      untilDestroyed(this)
    ).subscribe(() => this.layoutService.expandSidenav());

    /**
     * Open/Close Quickpanel through LayoutService
     */
    this.layoutService.quickpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.quickpanel.open() : this.quickpanel.close());


    //dynamic 2022
    this.layoutService.searchOpen$.pipe(
      untilDestroyed(this)
    ).subscribe((state) => {
      if (state) {
        // create the component factory  
        const dynamicComponentFactory = this.componentFactoryResolver
          .resolveComponentFactory(this.layoutService.searchComponentElement);
        // add the component to the view  
        const componentRef = this.searchContainer.createComponent(dynamicComponentFactory);
      } else {
        this.searchContainer.remove();
        this.layoutService.searchComponentElement = null;
      }
    });

    /**
     * Open/Close Sidenav through LayoutService
     */
    this.layoutService.sidenavOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.sidenav.open() : this.sidenav.close());

    /**
     * Mobile only:
     * Close Sidenav after Navigating somewhere (e.g. when a user clicks a link in the Sidenav)
     */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      withLatestFrom(this.isDesktop$),
      filter(([event, matches]) => !matches),
      untilDestroyed(this)
    ).subscribe(() => this.sidenav.close());
  }

  scrollEvent = (event: any): void => {
    // console.log(this.addRemovePadding)
    const number = event.srcElement.scrollTop;
    if (number > 100) {
      if (number > 600) {
        this.addClass.classList.remove("fixed-table-header");
        this.table.classList.remove("fixed-row");
        this.removeClass.classList.remove("newToolbar");
        this.table.classList.add("fixed-row-top")
        this.addRemovePadding.classList.remove("content-padding")
      }
      else if (number > 100) {
        this.addClass.classList.add("fixed-table-header");
        this.removeClass.classList.remove("newToolbar");
        this.addRemovePadding.classList.remove("content-padding")
        this.table.classList.add("fixed-row")
        this.table.classList.remove("fixed-row-top")
      }
      this.windowScrolled = true
      console.log(this.windowScrolled)
    }
    else if (number < 100) {
      this.addClass.classList.remove("fixed-table-header");
      this.removeClass.classList.add("newToolbar");
      this.addRemovePadding.classList.add("content-padding");
      this.table.classList.remove("fixed-row");
      this.table.classList.remove("fixed-row-top")
      this.windowScrolled = false
    }


  }


  ngAfterViewInit(): void {
    /**
     * Enable Scrolling to specific parts of the page using the Router
     */
    this.router.events.pipe(
      filter<Event, Scroll>((e: Event): e is Scroll => e instanceof Scroll),
      untilDestroyed(this)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        this.sidenavContainer.scrollable.scrollTo({
          start: e.position[0],
          top: e.position[1]
        });
      } else if (e.anchor) {
        // anchor navigation

        const scroll = (anchor: HTMLElement) => this.sidenavContainer.scrollable.scrollTo({
          behavior: 'smooth',
          top: anchor.offsetTop,
          left: anchor.offsetLeft
        });

        let anchorElem = this.document.getElementById(e.anchor);

        if (anchorElem) {
          scroll(anchorElem);
        } else {
          setTimeout(() => {
            anchorElem = this.document.getElementById(e.anchor);
            scroll(anchorElem);
          }, 100);
        }
      } else {
        // forward navigation
        this.sidenavContainer.scrollable.scrollTo({
          top: 0,
          start: 0
        });
      }
    });
  }


  scrollToTop() {
    (function smoothscroll() {

      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }

    })();
  }

  closedSideBar(){
    console.log("closed")
  }
}
