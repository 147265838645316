import { Injectable , EventEmitter} from '@angular/core';
import { colorThemeBase, ColorVariable, colorVariables } from 'src/@vex/components/config-panel/color-variables';
import { ColorSchemeName } from 'src/@vex/config/colorSchemeName';
import { VexConfigName } from 'src/@vex/config/config-name.model';
import { ConfigService } from 'src/@vex/config/config.service';
import { User } from 'src/app/models/users.model';
import { CommonService } from 'src/app/shared/common.service';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  userUpdated = new EventEmitter();

  private _user: User = new User({});
  public get user(): User {
    return this._user;
  }
  public set user(value: User) {
    this._user = value;
    this.onLoginUserChange(value);
    this.userUpdated.emit(value);
    this.inputUserSettingsToUI(value.settings);
  }

  public selectedUser: User = new User({});
  public selectedLocation: any = {};

  public selectedBrandMap: any = {};
  public selectedBrand: any = {};
  public selectedInvoice: any;

  public token: string = "\0";
  public otp: string = "";
  public accessToken: string | null = "\0";
  public refreshToken: string | null = "\0";
  public isLoading: boolean = false;

  constructor(private configService: ConfigService, private commonService:CommonService) { }

  resetGlobal() {
    this.selectedUser = new User({});
    this.selectedLocation = {};
    this.selectedBrandMap = {};
    this.selectedBrand = {};
  }
  onLoginUserChange(user:User) {
    let footer_type:any = null;
    if(!user.is_email_verified&&!user.parent_id){
      footer_type = 'subscription';
    }else if(!user.has_payment_method){
      footer_type = 'payment_method';
    }
    if(user.is_superuser){
      footer_type = null;
    }

    this.configService.updateConfig({
      footer: {
        visible: footer_type?true:false,
        type: footer_type
      }
    });
  }
  inputUserSettingsToUI(settings){
    if(settings?.theme){
      let theme = settings?.theme;
      // if(theme.mode){
      //   let color= {
      //     key:theme.mode,
      //     value:colorThemeBase[theme.mode]
      //   }
      //   this.setConfig_poseidon_dynamic(color.value.ColorSchemeName,color.value, color.key)
      // }
      if(theme.color){
        // let color= {
        //   key:theme.color,
        //   value:colorVariables[theme.color]
        // }
        this.selectColor(theme.color);
      }
      if(theme.mode==ColorSchemeName.dark){
        this.enableDarkMode();
      }else{
        this.disableDarkMode();
      }

      if (theme.vertical_display?.text === "Vertical") {
        this.commonService.themeControl.mode = "vertical";
        this.configService.setConfig(VexConfigName.apollo);
      } else {
        this.commonService.themeControl.mode = "horizontal";
        this.configService.setConfig(VexConfigName.poseidon);
      }

      if(theme.border_radius){
        this.configService.updateConfig({
          style: {
            borderRadius: theme.border_radius
          }
        });
      }
      this.onLoginUserChange(this.user);
    }
  }

  private selectColor(color: ColorVariable): void {
    this.configService.updateConfig({
      style: {
        colors: {
          primary: {
            default: color.default,
            contrast: color.contrast
          }
        }
      }
    });
    // document.documentElement.style.setProperty('--my-primary', this.rgbToHex(color.default));
  }
  private enableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.dark
      }
    });
    this.commonService.themeControl.theme = "dark";
  }

  private disableDarkMode(): void {
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.light
      }
    });
    this.commonService.themeControl.theme = "light";
  }
}
