import { EventEmitter, Injectable, Output } from '@angular/core';
import { Notifications } from '@mobiscroll/angular';
import { MbscNumpadOptions } from '@mobiscroll/angular4';
import { Observable } from '@mobiscroll/angular4/src/js/util/observable';
import { Subject } from 'rxjs';
import { ColorSchemeName } from 'src/@vex/config/colorSchemeName';
import { ConfigService } from 'src/@vex/config/config.service';
import { item } from 'src/@vex/interfaces/link.interface';
import { LayoutService } from 'src/@vex/services/layout.service';
import { access, addIntegrationForm, brandSetting, darkMode, DefaultHours, deviceForm, deviceType, hideHeader, hoursEditButton, locationsidebar, menuForm, orderForm, packageForm, printer, redirection, reportControl, subUser, todaysStatushours } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  @Output() BrandDetailsResetEvent = new EventEmitter<string>();
  BrandDetailReset() {
    this.BrandDetailsResetEvent.emit();
  }
  @Output() SpecialClosureCancelEvent = new EventEmitter<string>();
  onSpecialClosureCancel() {
    debugger;
    this.SpecialClosureCancelEvent.emit();
  }

  subScriptionVisible: any = false;
  accessVisible: any = false;
  accessControl: access = { isVisible: false, route: '' }
  hideControl: hideHeader = { isVisible: false }
  reportControl: reportControl = { isVisible: false }
  deviceType: deviceType = { deviceStyle: '', os: '' }
  packageControl: packageForm = { edit: false }
  hoursEditButtonVisible: hoursEditButton = { editVisible: true, visibleDays: false }
  todaysStatushoursButtonVisible: todaysStatushours = { editVisible: true, visibleDays: false }
  deviceControl: deviceForm = { visiblecontrol: true, disableStep: false }
  menuControl: menuForm = { previousPage: '', currentPage: '', isVisible: true }
  orderDetail: orderForm = { adjustment: false }
  addIntegration: addIntegrationForm = { control: 'brand' }
  brandSetting: brandSetting = { integration: true, website: true, name: "", type: "", editIntegration: true, logo: "", editParseIntegration: false }
  printerControl: printer = { advance: false }
  themeControl: darkMode = { theme: "light", mode: "horizontal" }
  subUser: subUser = { visible: false }
  commonRedirection: redirection = { image: "", name: "", subName: "", isVisible: false }
  locationSideBar: locationsidebar = { location: "" };
  locationSideBarSubject = new Subject<boolean>();
  categorySideBarSubject = new Subject<boolean>();
  isVisibleDefaultHours: DefaultHours = { isvisible: false }
  isDesktop$ = this.layoutService.isDesktop$;
  dusSettings: MbscNumpadOptions = {
    display:  'bubble',
    cssClass: "nubpad-phone",
    theme: this.deviceType.deviceStyle,
    themeVariant: 'light',
    fill: 'ltr',
    template: '+1-ddd-ddd-dddd',
    showOnTap: true,
    allowLeadingZero: true,
    // leftKey: {
    //   text: "Code",
    //   variable: 'ampm:AM',
    //   value: "",
    // },
    // onInput: (event, inst) => {
    //   if (event.target.classList.contains('mbsc-np-btn-custom-left')) {
    //     this.horizontal.show();
    //   }
    // },
    validate: (event, inst) => {
      if (inst.isVisible()) {
        inst._markup[0].querySelector('.mbsc-np-dsp').innerHTML = inst.settings.formatValue(event.values, event.variables, inst) || '&nbsp;';
      }
      return {
        invalid: event.values.length != 10
      };
    },
    formatValue: (numbers, variables, inst) => {
      return '+1-' + numbers.slice(0, 3).join('') + (numbers.length > 2 ? '-' : '') + numbers.slice(3, 6).join('') + (numbers.length > 5 ? '-' : '') + numbers.slice(6, 10).join('');
    },
    parseValue: (v) => {
      if (v) {
        return v.replace('+1', '').replace(/-/gm, '');;
      }
    }
  };

  clouser: any[] = [{
    primary: false,
    clickMessage: "Delete",
    closed: false,
    label: "Special Closure",
    name: "closure1"
  }];

  contactItems: item[] = [
    {
      label: 'Owner',
      value: 'owner',
      saveVisible: false,
    },
    {
      label: 'Manager',
      value: 'manager',
      saveVisible: false,
    },
    {
      label: 'Operator',
      value: 'operator',
      saveVisible: false,
    }
  ];

  brandItems: item[] = [
    {
      label: 'Brand 1',
      value: 'brand1',
      saveVisible: false,
    }
  ];

  deviceItems: item[] = [
    {
      label: 'Device 1',
      value: 'device1',
      saveVisible: false,
    },
    {
      label: 'Device 2',
      value: 'device2',
      saveVisible: false,
    }
  ];

  categoryItems: item[] = [
    {
      label: 'Category 1',
      value: 'Category1',
      saveVisible: false,
    }
  ];

  locationItems: item[] = [
    {
      label: 'location 1',
      value: 'location1',
      saveVisible: false,
    }
  ];

  printerItems: item[] = [
    {
      label: 'Printer 1',
      value: 'Printer1',
      saveVisible: false,
    }
  ];


  constructor(public notify: Notifications,
    public layoutService: LayoutService,
    public configService: ConfigService
  ) {
    this.configService.config$.subscribe(config => {
      this.configService.config$.subscribe(config => {
        //this.dusSettings.themeVariant = config.style.colorScheme === ColorSchemeName.dark ? 'dark' : 'light';
        //this.dusSettings.theme = this.deviceType.deviceStyle;
        this.dusSettings = {
          display:  'bubble',
          cssClass: "nubpad-phone",
          theme: this.deviceType.deviceStyle,
          themeVariant: config.style.colorScheme === ColorSchemeName.dark ? 'dark' : 'light',
          fill: 'ltr',
          template: '+1-ddd-ddd-dddd',
          allowLeadingZero: true,
          // leftKey: {
          //   text: "Code",
          //   variable: 'ampm:AM',
          //   value: "",
          // },
          // onInput: (event, inst) => {
          //   if (event.target.classList.contains('mbsc-np-btn-custom-left')) {
          //     this.horizontal.show();
          //   }
          // },

          validate: (event, inst) => {
            if (inst.isVisible()) {
              inst._markup[0].querySelector('.mbsc-np-dsp').innerHTML = inst.settings.formatValue(event.values, event.variables, inst) || '&nbsp;';
            }
            return {
              invalid: event.values.length != 10
            };
          },
          formatValue: (numbers, variables, inst) => {
            return '+1-' + numbers.slice(0, 3).join('') + (numbers.length > 2 ? '-' : '') + numbers.slice(3, 6).join('') + (numbers.length > 5 ? '-' : '') + numbers.slice(6, 10).join('');
          },
          parseValue: (v) => {
            if (v) {
              return v.replace('+1', '').replace(/-/gm, '');;
            }
          }
        };
      });
    });

    this.layoutService.isDesktop$.subscribe(x => {
      this.dusSettings.display = x ? 'bubble' : 'bottom';
      this.dusSettings.theme = this.deviceType.deviceStyle;
    });
  }


  setsubScriptionVisible(val: boolean) {
    this.subScriptionVisible = val;
  }

  getsubScriptionVisible() {
    return this.subScriptionVisible;
  }

  getUserWiseAccess() {
    return this.accessVisible;
  }

  setUserWiseAccess(val: boolean) {
    this.accessVisible = val;
  }


  deleteConfirmation(): Promise<boolean> {
    return this.notify.confirm({
      title: 'Account Activation',
      message: "Are you sure you want to delete?",
      okText: 'Ok',
      cancelText: 'Cancel',
    }).then((result) => {
      return result;
    });
  }
  items: item[] = [
    {
      label: 'Item 1',
      value: 'Item1',
      saveVisible: false,
    }
    // ,
    // {
    //   label: 'Item 2',
    //   value: 'Item2',
    //   saveVisible: false,
    // }
  ];

  sectionsItems: item[] = [
    {
      label: 'Sections 1',
      value: 'Sections1',
      saveVisible: false,
    }
    // ,
    // {
    //   label: 'Sections 2',
    //   value: 'Sections2',
    //   saveVisible: false,
    // }
  ];

  modifiers: item[] = [
    {
      label: 'Modifiers 1',
      value: 'Modifiers1',
      saveVisible: false,
    }
    // ,
    // {
    //   label: 'Modifiers 2',
    //   value: 'Modifiers2',
    //   saveVisible: false,
    // }
  ];
  modifiersGroup: item[] = [
    {
      label: 'M.Groups 1',
      value: 'MGroups1',
      saveVisible: false,
    }
    // ,
    // {
    //   label: 'M.Groups 2',
    //   value: 'MGroups2',
    //   saveVisible: false,
    // }
  ];

  upsellGroup: item[] = [
    {
      label: 'Upsell Group 1',
      value: 'UGroups1',
      saveVisible: false,
    }

  ];
}
