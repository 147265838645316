import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { LayoutService } from 'src/@vex/services/layout.service';
import { SidebarComponent } from 'src/@vex/components/sidebar/sidebar.component';
import { Router } from '@angular/router';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'profile',
      description: '',
      colorClass: 'text-teal',
      route: '/apps/social'
    },
    {
      id: '2',
      icon: 'mat:move_to_inbox',
      label: ' switch account',
      description: '',
      colorClass: 'text-primary',
      route: '/apps/chat'
    },
    {
      id: '3',
      icon: 'mat:list_alt',
      label: 'Configuration',
      description: 'Manage Theme',
      colorClass: 'text-amber',
      route: ''
    },
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(private cd: ChangeDetectorRef, private router: Router,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>, private layoutService: LayoutService) { }

  ngOnInit() {

  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  openConfigpanel() {
    // this.popoverRef.close();
    this.configpanel.open();
    // this.layoutService.openConfigpanel();
  }

  profile() {
    this.router.navigate(['/profile']);
  }
}
