<div *ngIf="config$ | async as setting" class="config-panel">
  <div class="display-flex justify-space-between align-center">
    <h2 class="headline">
      <mat-icon class="mr-3" svgIcon="mat:settings"></mat-icon>
      <span>Settings</span>
    </h2>

    <div class="align-center mt-2 pointer">
      <mat-icon svgIcon="mat:close" (click)="closed()"></mat-icon>
    </div>
  </div>

  <div class="section setting">
    <h5 class="subheading">DISPLAY</h5>
    <div class="grid grid-cols-4 gap-4">
      <div *ngFor="let menu of menuValues; let i=index" [class.border-gray-300]="">
        <div class="h-12 w-12 border-2 trans-ease-out border-gray-500 horizontal-icon background-transparent"
          style="margin-bottom: -2px; margin-right: -2px" [ngClass]="{ 'border-primary': is_menu_selected(menu) }"
          (click)="isSelectedBorderRadius_Menu(menu,i)">
          <mat-icon svgIcon="mat:horizontal_split" *ngIf="menu?.text == 'Horizontal'"
            [ngClass]="{ 'bg-color-primary border-primary': is_menu_selected(menu) }"
            style="transform: rotate(180deg);">
          </mat-icon>
          <mat-icon svgIcon="mat:vertical_split" *ngIf="menu?.text == 'Vertical'"
            [ngClass]="{ 'bg-color-primary border-primary': is_menu_selected(menu) }"
            style="transform: rotate(180deg);">
          </mat-icon>
        </div>
      </div>

      <div (click)="selectBorderRadius(borderRadius)" *ngFor="let borderRadius of roundedCornerValues"
        [class.border-gray-300]=""
        [ngClass]="{'border-gray-300': isSelectedBorderRadius(borderRadius, setting), 'border-transparent': !isSelectedBorderRadius(borderRadius, setting) }"
        class="">
        <div
          [ngClass]="{ 'bg-color-primary border-primary': isSelectedBorderRadius(borderRadius, setting), 'bg-foreground border-gray-500 group-hover:bg-app-bar': !isSelectedBorderRadius(borderRadius, setting) }"
          [style.border-top-left-radius]="borderRadius.value + borderRadius.unit"
          class="h-12 w-12 border-2 trans-ease-out horizontal-icon background-transparent"
          style="margin-bottom: -2px; margin-right: -2px">
          <mat-icon svgIcon="mat:filter_center_focus" *ngIf="borderRadius.value == 0.5">
          </mat-icon>
          <mat-icon svgIcon="mat:filter_tilt_shift" *ngIf="borderRadius.value == 1.75">
          </mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="section Preference-border">
    <h5 class="subheading">Preference</h5>
    <div (click)="isDark ? disableDarkMode() : enableDarkMode()" *ngrxLet="isDark$; let isDark"
      class="rounded-full mt-2 flex items-center cursor-pointer relative select-none"
      [ngClass]="{'bg-contrast-grey text-contrast-black': !isDark , 'bg-contrast-black text-contrast-white': isDark }"
      matRipple>
      <div class="m-2 h-6 w-6 rounded-full">
        <mat-icon *ngIf="isDark" svgIcon="mat:brightness_3" class="theme-base-text-dark-color"></mat-icon>
        <mat-icon *ngIf="!isDark" svgIcon="mat:wb_sunny" class="theme-base-text-light-color"></mat-icon>
      </div>
      <p class="ml-1 font-medium text-sm"
        [ngClass]="{ 'theme-base-text-light-color': !isDark, 'theme-base-text-dark-color': isDark }">{{isDark ? "Dark
        Mode" : "Light Mode"}}</p>
    </div>
  </div>



  <div class="section Preference-border">
    <div
      class="rounded-full mt-2 flex items-center cursor-pointer relative select-none bg-contrast-grey text-contrast-black"
      matRipple>
      <div class="d-flex-justify align-center">
        <div class="d-flex-align-center" (click)="preference('call')">
          <div class="m-2 h-6 w-6 rounded-full">
            <mat-icon svgIcon="mat:call" class="preference-text-color"
              [ngClass]="{ 'selected-preference': this.preferenceType == 'call' }">
            </mat-icon>
          </div>
          <p class="font-medium text-sm preference-text-color"
            [ngClass]="{ 'selected-preference': this.preferenceType == 'call' }">Call</p>
        </div>
        <div class="d-flex-align-center" (click)="preference('email')">
          <div class="m-2 h-6 w-6 rounded-full">
            <mat-icon svgIcon="mat:email" class="preference-text-color"
              [ngClass]="{ 'selected-preference': this.preferenceType == 'email' }">
            </mat-icon>
          </div>
          <p class="font-medium text-sm preference-text-color"
            [ngClass]="{ 'selected-preference': this.preferenceType == 'email' }">Email</p>
        </div>
        <div class="d-flex-align-center" (click)="preference('msg')">
          <div class="m-2 h-6 w-6 rounded-full">
            <mat-icon svgIcon="mat:message" class="preference-text-color"
              [ngClass]="{ 'selected-preference': this.preferenceType == 'msg' }">
            </mat-icon>
          </div>
          <p class="font-medium text-sm preference-text-color"
            [ngClass]="{ 'selected-preference': this.preferenceType == 'msg' }">Msg</p>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="h-6 mt-2 w-6 mb-3 rounded-full">
      <mbsc-dropdown inputStyle="box" class="timezone">
        <option value="1">UTC-8 Los Angeles</option>
        <option value="2">UTC-6 Chicago</option>
        <option value="3">UTC-5 New York</option>
        <option value="4">UTC</option>
        <option value="5">UTC+0 London</option>
        <option value="6">UTC+1 Berlin </option>
        <option value="7">UTC+2 Bucharest</option>
        <option value="8">UTC+5.5 Local</option>
        <option value="9">UTC+8 Shanghai</option>
        <option value="10">UTC+8 Tokyo</option>
      </mbsc-dropdown>
    </div>
  </div>



  <div class="section">
    <h5 class="subheading">THEME COLORS</h5>

    <div (click)="selectColor(color.value)" *ngFor="let color of colorVariables | keyvalue"
      [class.selected]="isSelectedColor(color.value)&&!foreColorSelected" [style.backgroundColor]="color.value.light"
      [style.color]="color.value.default"
      class="vex-color-picker rounded-full mt-2 flex items-center cursor-pointer relative" matRipple>
      <div [style.backgroundColor]="color.value.default" class="m-2 h-6 w-6 rounded-full">
        <mat-icon *ngIf="isSelectedColor(color.value)&&!foreColorSelected" svgIcon="mat:check"></mat-icon>
      </div>
      <p class="ml-1 font-medium text-sm">{{ color.key | uppercase }}</p>
    </div>

    <input type="color" id="pickColor" name="head" placeholder="PICK A COLOR" innerHTML="PICK A COLOR"
      innerText="PICK A COLOR" class="freeColor mt-2 rounded-full cursor-pointer absolute" (input)="freeColor($event)">
    <div class="m-2 h-6 w-6 rounded-full choose-color-circle">
      <mat-icon svgIcon="mat:check" *ngIf="this.foreColorSelected"></mat-icon>
    </div>
  </div>


  <div class="section"
    [ngClass]="{'ios-section-bottom': this.commonService.deviceType.deviceStyle == 'ios', 'section-bottom': this.commonService.deviceType.deviceStyle == 'material'}">
    <h5 class="subheading">Language</h5>
    <div
      class="rounded-full mt-2 flex items-center cursor-pointer relative select-none bg-contrast-grey text-contrast-black"
      matRipple *ngFor="let color of langagueVariables | keyvalue;let i=index" (click)="langague(color.value.text)">
      <div class="d-flex-justify align-center">
        <div class="m-2 h-6 w-6 rounded-full">
          <img [src]="color.value.image" class="avatar h-6 w-8 align-middle avatar-radius"
            [ngClass]="{'selected-value ': color.value.text === selectedLangague}">
        </div>
        <p class="ml-5 font-medium text-sm">{{color.value.text}}</p>
      </div>
      <div class="m-2 h-6 gap-2 rounded-full display-flex w-100 justify-end">
        <mat-icon svgIcon="mat:check" *ngIf="color.value.text === selectedLangague"></mat-icon>
      </div>
    </div>
    <div class="h-6 w-6 mt-2 rounded-full langagues">
      <mbsc-dropdown inputStyle="box" class="timezone">
        <option value="1"> Español</option>
        <option value="2">Norsk</option>
        <option value="3">Türkçe</option>
        <option value="4">Polski</option>
        <option value="5">Ukrainian</option>
        <option value="6">Bulgarian</option>
        <option value="7">Greek</option>
        <option value="8">Português Eu.</option>
        <option value="9">Vietnamese</option>
        <option value="10">Català</option>
        <option value="11">Pt. Brasileiro</option>
        <option value="12">Русский</option>
        <option value="13">Cestina</option>
        <option value="14">Italiano</option>
        <option value="15">Româna</option>
        <option value="16">Русский (UA)</option>
        <option value="17">Japanese</option>
        <option value="18">Serbian</option>
        <option value="19">עברית</option>
        <option value="20">Croatian</option>
        <option value="21">Korean</option>
        <option value="22">Slovencina</option>
        <option value="23">فارسی</option>
        <option value="24">Dansk</option>
        <option value="25">Lietuvių</option>
        <option value="26">Suomi</option>
        <option value="27">Lietuvių</option>
        <option value="28">Deutsch</option>
        <option value="29">Magyar</option>
        <option value="30">Svenska</option>
        <option value="31">English (GB)</option>
        <option value="32">Nederlands</option>
        <option value="33">Thai</option>
      </mbsc-dropdown>
    </div>
    <div class="actions pd-top-6 flex items-center justify-end gap-2"
      [ngClass]="{'pt': this.commonService.deviceType.deviceStyle == 'ios'}">
      <button (click)="resetSettings()" color="primary" mat-button type="button">Reset
      </button>
      <button color="primary" mat-raised-button (click)="saveUserSettings()">
        Save
      </button>
    </div>
  </div>
</div>