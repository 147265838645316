
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from './global.service';
import { UrlService } from './url.service';
@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient,
    private snackbar: MatSnackBar,
  private global:GlobalService,public tablessURL:UrlService) { }

  request(url: string,data: any,type: number):Promise<Response>{
    if(type==0){
      return this.get(url,data,1);
    }
    else{
      return this.post(url,data,1);
    }
  }
  fetch(url: any,data: any,type: number):Promise<Response>{
    if(type==0){
      return this.get(url,data,0);
    }
    else{
      return this.post(url,data,0);
    }
  }
  get(url: string,data: any,isAuth: number):Promise<Response>{

    let options;
    if(isAuth){
      let headers = new HttpHeaders({ 'Authorization': 'JWT ' + this.global.token });
      options = { headers: headers };
    }else{
      options = {};
    }

    return this.http.get(url,options)
      .toPromise()
      .catch((error)=>{
        this.errorHandler(error);
        return error;
      });
  }

  post(url: string,data: any,isAuth: number):Promise<Response>{
    let options;
    if(isAuth){
      if(this.global.token!='\0'){
        let headers = new HttpHeaders({ 'Authorization': 'JWT ' + this.global.token });
        options = { headers: headers };
      }else{
        options = {};
      }

    }else{
      options = {};
    }
    console.log("post working");
    return this.http.post(url,data,options)
      .toPromise()
      .catch((error)=>{
        this.errorHandler(error);
        return error;
      });
  }

  errorHandler(error: { status: number; }|any){
    if(error.status==401){
      debugger;
      this.logout();
    }
    let errMsg: string;
    if (error.error?.message) {
      const body: any = error;
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''}, ${err.message}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    this.snackbar.open(errMsg, 'Dismiss', {
      duration: 5000
    });
  }

  logout(): void {
        // clear token remove user from local storage to log user out
        this.global.accessToken = null;
        this.global.refreshToken=null;
        localStorage.removeItem('GotablessUser');
        // this.router.navigate(['/login']);
  }


  refreshToken(error: { ok: any; status: number; },data: any,url: any,type: any){
    console.log("error",error);
    if(!error.ok&&error.status==401){
      console.log("abcd !",this.global.refreshToken);
      console.log("token :",this.global.token)
    console.log("redfresh",this.global.refreshToken);

      let headers = new HttpHeaders({ 'X-Authorization': 'Bearer ' + this.global.refreshToken });
      let options = { headers: headers };

      this.http.get(this.tablessURL.refreshToken,options)
        .toPromise()
        .then((success)=>{
          let body:any=success;
          body=JSON.parse(body._body);
          this.global.token=body.token;
          let gotablessUser:any = localStorage.getItem('GotablessUser')
          gotablessUser=JSON.parse(gotablessUser?gotablessUser:'');
          gotablessUser.token=body.token;
          localStorage.setItem('GotablessUser',JSON.stringify(gotablessUser));
          location.reload();

        })
        .catch();
    }
  }
}
