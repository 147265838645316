import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Notifications } from '@mobiscroll/angular';
import { FreshdeskService } from 'src/app/common/services/freshdesk/freshdesk.service';
import { SharedService } from 'src/app/services/shared.service';
import { PopoverRef } from '../popover/popover-ref';

declare var fcWidget: any;

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  @ViewChild('confirmhorizontal') confirmhorizontal: any;
  @Output() openConfig = new EventEmitter();
  bubblePopup: any;
  alertFormGroup: UntypedFormGroup;
  constructor(private readonly popoverRef: PopoverRef, private router: Router, public notify: Notifications,
    private freshdeskService: FreshdeskService, private sharedService: SharedService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.alertFormGroup = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      twofa: [null],
      twofaSwitch: [null],
    });
  }
  openConfigData() {
    let data = 'settings'
    this.sharedService.searchData(data)
    this.popoverRef.close()
  }
  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }
  profile() {
    this.router.navigate(['/profile']);
    setTimeout(() => this.popoverRef.close(), 250);
  }

  changeEmail() {
    this.notify.prompt({
      title: 'Change your User Name',
      message: "Resend activation link to new User Name instead of 'admin@gotabless.com'",
      placeholder: 'Email',
      okText: 'Ok',
      cancelText: 'Cancel',
    });
  }
  changeSecurity() {
    this.notify.prompt({
      title: 'Security',
      message: "Enter your password",
      placeholder: 'Password',
      okText: 'Ok',
      cancelText: 'Cancel',
    }).then((result) => {
      if (result) {
        this.changeSecurityUserDetails();
      }
    });
  }
  changeSecurityUserDetails() {
    this.bubblePopup = "#alertHorizontal";
    this.confirmhorizontal.show();
  }
  changePassword() {
    this.notify.prompt({
      title: 'Change your password',
      message: "Are you sure you want to reset password?",
      inputType: 'password',
      placeholder: 'Password',
      cancelText: 'Cancel',
    });
  }

  support() {
    // fcWidget.init({
    //   token: "0e80486d-e178-4fdb-b40a-ebf9be6a04b8",
    //   host: "https://tabless-help.freshchat.com",
    //   faqTags: {
    //     // For ex: ["public", "paid"]
    //     tags: [],
    //     //For articles, the below value should be article.
    //     //For article category, the below value should be category.
    //     filterType: 'article' //Or filterType: 'category'
    //   },
    //   config: {
    //     headerProperty: {
    //       hideChatButton: true,
    //     },
    //     cssNames: {
    //       widget: 'fc_frame',
    //       open: 'fc_open',
    //       expanded: 'fc_expanded' 
    //     },
    //     // showFAQOnOpen: true,
    //     hideFAQ: false,
    //   },
    // });
    // fcWidget.open();
    this.freshdeskService.open();
    setTimeout(() => this.popoverRef.close(), 100);
  }
}
