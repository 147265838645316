import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Notifications } from '@mobiscroll/angular';
import { MbscPopupOptions } from '@mobiscroll/angular4';
import { ColorSchemeName } from 'src/@vex/config/colorSchemeName';
import { ConfigService } from 'src/@vex/config/config.service';
import { CommonService } from '../common.service';

@Component({
  selector: 'vex-center-popup',
  templateUrl: './center-popup.component.html',
  styleUrls: ['./center-popup.component.scss']
})
export class CenterPopupComponent implements OnInit {
  @ViewChild('horizontalPopup') horizontalPopup: any;
  @Output() bookTitleCreated = new EventEmitter<{ title: string }>();
  @Output() orderAvailebility = new EventEmitter<{ title: string }>();
  @Output('filter') onFilterChange = new EventEmitter<any>();
  @Input() bubblePopupValue: any;
  horizontalSettings: MbscPopupOptions = {

  };


  constructor(public commonService: CommonService,
    public configService: ConfigService,
    public notify: Notifications) {

    this.configService.config$.subscribe(config => {

      this.horizontalSettings = {
        display: 'center',
        anchor: '#showHorizontal',
        cssClass: "center-box-shadow",
        buttons: ["ok", "cancel"],
        theme: this.commonService.deviceType.deviceStyle,
        themeVariant: config.style.colorScheme === ColorSchemeName.dark ? 'dark' : 'light',
        onSet: (event, inst) => {
          debugger;
          this.bookTitleCreated.emit({ title: "Test" });
          this.orderAvailebility.emit({ title: "Test" });
          this.onFilterChange.emit({ updated: new Date() });
        }
      };
    });
  }

  ngOnInit(): void {

  }

  show(): void {
    this.horizontalPopup?.instance?.show()
  }

  showPopup(target: any): void {
    this.horizontalPopup.instance.settings.anchor = target;
    this.horizontalPopup?.instance?.show();
  }
}

