import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserMenuComponent } from 'src/@vex/components/user-menu/user-menu.component';
import { GlobalService } from 'src/app/common/services/global.service';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  user;
  constructor(private popover: PopoverService,
    private router: Router,
    public global:GlobalService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.user = this.global.user;
    this.global.userUpdated.subscribe((v)=>{
      this.user = v;
      this.cd.markForCheck()
      this.cd.detectChanges();
    });
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: UserMenuComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  profile() {
    this.router.navigate(['/profile']);
  }
  getLoggedUser(){
    return this.global.user;
  }
}
