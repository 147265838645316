import { lightFormat } from "date-fns";
import { ColorSchemeName } from "src/@vex/config/colorSchemeName";

export interface ColorVariable {
  light: string;
  default: string;
  contrast: string;
  ColorSchemeName: string;
  text?: string;
  defaultHex?: string;
  textColor?: string;
}

export interface LanagugeVariable {
  light: string;
  default: string;
  contrast: string;
  text?: string;
  image?: string;
}

export const colorVariables: Record<string, ColorVariable> = {
  IndigoHamlet: {
    light: 'rgba(33, 66, 143, .1)',
    default: 'rgb(33, 66, 143)',
    defaultHex: '#21428F',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-light'
  },
  PrunusAvium: {
    light: 'rgba(220, 64, 142, 0.1)',
    default: 'rgb(220, 64, 142)',
    defaultHex: '#DC408E',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-light'
  },
  White: {
    light: 'rgba(255, 255, 255, 0.1)',
    default: 'rgb(255, 255, 255)',
    defaultHex: '#fff',
    contrast: 'rgb(0, 0, 0)',
    ColorSchemeName: 'vex-style-light'
  },
  // red: {
  //   light: 'rgba(244, 67, 54, 0.1)',
  //   default: 'rgb(244, 67, 54)',
  //   defaultHex: '#F44336',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // orange: {
  //   light: 'rgba(255, 152, 0, 0.1)',
  //   default: 'rgb(255, 152, 0)',
  //   defaultHex: '#FF9800',
  //   contrast: 'rgb(0, 0, 0)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // 'deep-orange': {
  //   light: 'rgba(255, 87, 34, 0.1)',
  //   default: 'rgb(255, 87, 34)',
  //   defaultHex: '#FF5722',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // amber: {
  //   light: 'rgba(255, 193, 7, 0.1)',
  //   default: 'rgb(255, 193, 7)',
  //   defaultHex: '#FFC107',
  //   contrast: 'rgb(0, 0, 0)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // green: {
  //   light: 'rgba(76, 175, 80, 0.1)',
  //   default: 'rgb(76, 175, 80)',
  //   defaultHex: '#4CAF50',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // teal: {
  //   light: 'rgba(0, 150, 136, 0.1)',
  //   default: 'rgb(0, 150, 136)',
  //   defaultHex: '#009688',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // cyan: {
  //   light: 'rgba(0, 188, 212, 0.1)',
  //   default: 'rgb(0, 188, 212)',
  //   defaultHex: '#00BCD4',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // purple: {
  //   light: 'rgba(156, 39, 176, 0.1)',
  //   default: 'rgb(156, 39, 176)',
  //   defaultHex: '#9C27B0',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // 'deep-purple': {
  //   light: 'rgba(103, 58, 183, 0.1)',
  //   default: 'rgb(103, 58, 183)',
  //   defaultHex: '#673AB7',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
  // pink: {
  //   light: 'rgba(233, 30, 99, 0.1)',
  //   default: 'rgb(233, 30, 99)',
  //   defaultHex: '#E91E63',
  //   contrast: 'rgb(255, 255, 255)',
  //   ColorSchemeName: 'vex-style-light'
  // },
};

export const colorVariablesLight: Record<string, ColorVariable> = {
  IndigoHamlet: {
    light: 'rgba(33, 66, 143, .1)',
    default: 'rgb(33, 66, 143)',
    defaultHex: '#21428F',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-light'
  },
  PrunusAvium: {
    light: 'rgba(220, 64, 142, 0.1)',
    default: 'rgb(220, 64, 142)',
    defaultHex: '#DC408E',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-light'
  },
  Black: {
    light: 'rgba(0, 0, 0, 0.1)',
    default: 'rgb(0, 0, 0)',
    defaultHex: '#000',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-light'
  },
};

//dynamic 2022
export const colorThemeBase: Record<string, ColorVariable> = {
  auto: {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(158, 158, 158)',
    defaultHex: '#9e9e9e',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-light',
    text: 'Auto',
    textColor: 'rgb(255, 255, 255)',
  },
  dark: {
    light: 'rgba(26, 32, 46, .1)',
    default: 'rgb(26, 32, 46)',
    defaultHex: '#1a202e',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-dark',
    text: 'Dark',
    textColor: 'rgb(255, 255, 255)',
  },
  light: {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(158, 158, 158)',
    defaultHex: '#9e9e9e',
    contrast: 'rgb(255, 255, 255)',
    ColorSchemeName: 'vex-style-light',
    text: 'Light',
    textColor: 'rgb(255, 255, 255)',
  },
};

export const langagueThemeBase: Record<string, LanagugeVariable> = {
  1: {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(158, 158, 158)',
    contrast: 'rgb(255, 255, 255)',
    text: 'English',
    image: 'assets/img/avatars/english.jpg',
  },
  2: {
    light: 'rgba(26, 32, 46, .1)',
    default: 'rgb(26, 32, 46)',
    contrast: 'rgb(255, 255, 255)',
    text: 'Spanish',
    image: 'assets/img/avatars/Spanish.jpg',
  },
  3: {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(158, 158, 158)',
    contrast: 'rgb(255, 255, 255)',
    text: 'Arabic',
    image: 'assets/img/avatars/Arabic.jpg',
  },
  4: {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(158, 158, 158)',
    contrast: 'rgb(255, 255, 255)',
    text: 'Indian',
    image: 'assets/img/avatars/Indian.jpg',
  },
  5: {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(158, 158, 158)',
    contrast: 'rgb(255, 255, 255)',
    text: 'Chinese',
    image: 'assets/img/avatars/Chinese.png',
  },
}; 
