// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_base:"http://localhost:8000",
  // api_base:"http://154.3.45.149:5000",
  api_base:"http://198.203.31.44:8000",
  // api_base:"http://144.86.187.34:8800",
  freshdesk_token: '0e80486d-e178-4fdb-b40a-ebf9be6a04b8',
  freshdesk_url: "https://tabless-help.freshchat.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
