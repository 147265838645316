import { Component, Inject, Input } from '@angular/core';
import { ConfigService } from '../../config/config.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { map } from 'rxjs/operators';
import { LayoutService } from '../../services/layout.service';
import { MatRadioChange } from '@angular/material/radio';
import { VexConfigName } from '../../config/config-name.model';
import { colorThemeBase, ColorVariable, colorVariables, colorVariablesLight, LanagugeVariable, langagueThemeBase } from './color-variables';
import { DOCUMENT } from '@angular/common';
import { ColorSchemeName } from '../../config/colorSchemeName';
import { Observable } from 'rxjs';
import { VexConfig } from '../../config/vex-config.interface';
import { CSSValue, menuValue } from '../../interfaces/css-value.type';
import { isNil } from '../../utils/isNil';
import { defaultRoundedButtonBorderRadius } from '../../config/constants';
import { CommonService } from 'src/app/shared/common.service';
import { userList } from 'src/app/shared/constant';
import { Notifications } from '@mobiscroll/angular';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalService } from 'src/app/common/services/global.service';
import { UserService } from 'src/app/user/services/user.service';

@Component({
  selector: 'vex-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss']
})
export class ConfigPanelComponent {
  @Input() configpanel: SidebarComponent;
  configs: VexConfig[] = this.configService.configs;
  colorVariables: Record<string, ColorVariable> =  colorVariables;//this.commonService.themeControl.theme == 'dark' ? colorVariables : colorVariablesLight;
  colorThemeBase: Record<string, ColorVariable> = colorThemeBase;
  langagueVariables: Record<string, LanagugeVariable> = langagueThemeBase;
  userList = userList;
  menuStyle: any;
  preferenceType: any;
  selectedLangague: any;
  config$: Observable<VexConfig> = this.configService.config$;
  foreColorSelected: any;
  themeMode:any;


  isRTL$: Observable<boolean> = this.config$.pipe(
    map(config => config.direction === 'rtl')
  );

  isDark$: Observable<boolean> = this.config$.pipe(
    map(config => config.style.colorScheme === ColorSchemeName.dark)
  );

  //dynamic 2022
  isLIght$: Observable<boolean> = this.config$.pipe(
    map(config => config.style.colorScheme === ColorSchemeName.light)
  );

  borderRadius$: Observable<number> = this.config$.pipe(
    map(config => config.style.borderRadius.value)
  );

  ConfigName = VexConfigName;
  ColorSchemeName = ColorSchemeName;
  selectedColor = colorVariables.IndigoHamlet;
  selectedColor_dynamic = colorThemeBase.light;


  roundedCornerValues: CSSValue[] = [
    {
      value: 0.5,
      unit: 'rem'
    },
    {
      value: 1.75,
      unit: 'rem'
    },

  ];

  menuValues: menuValue[] = [
    {
      text: "Horizontal",
      isSelect: true,
    },
    {
      text: "Vertical",
      isSelect: false,
    }];

  selectedMenu: menuValue = this.menuValues[0];
  selectedBorderRadius: CSSValue = {
    value: 0.5,
    unit: 'rem'
  };
  roundedButtonValue: CSSValue = defaultRoundedButtonBorderRadius;
  theme_mode = ColorSchemeName.light;
  constructor(private configService: ConfigService,
    public notify: Notifications,
    private global: GlobalService,
    private layoutService: LayoutService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    public commonService: CommonService,
    private sharedService: SharedService) {
      this.resetSettings();
      this.global.userUpdated.subscribe((v) => {
        // debugger;
        this.resetSettings();

      });
      this.configService.config$.subscribe(config => {
        this.colorVariables = config.style.colorScheme === ColorSchemeName.dark ? colorVariables : colorVariablesLight;
      });
    }


  setConfig(layout: VexConfigName, colorScheme: ColorSchemeName): void {
    this.configService.setConfig(layout);
    this.configService.updateConfig({
      style: {
        colorScheme
      }
    });
  }

  setConfig_poseidon(colorScheme: ColorSchemeName): void {
    this.configService.setConfig(VexConfigName.poseidon);
    this.configService.updateConfig({
      style: {
        colorScheme
      }
    });
  }

  setConfig_poseidon_dynamic(text: any, color: ColorVariable): void {
    this.selectedColor_dynamic = color;
    this.configService.setConfig(VexConfigName.poseidon);
    // this.configService.updateConfig({
    //   style: {
    //     colorScheme: text
    //   },
    // });

    if (text == 'vex-style-dark') {
      this.configService.updateConfig({
        style: {
          colorScheme: text
        },
        logo: "assets/img/Logo/IconDark.svg"
      });
    }
    if (text == 'vex-style-light') {
      this.configService.updateConfig({
        style: {
          colorScheme: text
        },
        logo: "assets/img/Logo/IconLight.svg"
      });
    }
  }

  selectColor(color: ColorVariable): void {
    this.foreColorSelected = false;
    this.selectedColor = color;
    this.configService.updateConfig({
      style: {
        colors: {
          primary: {
            default: color.default,
            contrast: color.contrast
          }
        }
      }
    });
    // document.documentElement.style.setProperty('--my-primary', this.rgbToHex(color.default));
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(rgb) {
    let sep = rgb.indexOf(",") > -1 ? "," : " ";
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb.substr(4).split(")")[0].split(sep);

    let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;

    return "#" + r + g + b;
  }


  isSelectedColor(color: ColorVariable): boolean {

    return (color.ColorSchemeName == this.selectedColor.ColorSchemeName)&&(color.contrast == this.selectedColor.contrast)&&(color.default == this.selectedColor.default)&&(color.defaultHex == this.selectedColor.defaultHex);
  }

  //dynamic 2022
  isSelectedColor_dynamic(color: ColorVariable): boolean {
    return color === this.selectedColor_dynamic;
  }

  enableDarkMode(): void {
    this.theme_mode = ColorSchemeName.dark;
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.dark
      }
    });

    this.commonService.themeControl.theme = "dark"
    this.colorVariables = colorVariables;// this.commonService.themeControl.theme == 'dark' ? colorVariables : colorVariablesLight;
    this.sharedService.searchData(this.commonService.themeControl.theme);
  }

  disableDarkMode(): void {
    this.theme_mode = ColorSchemeName.light;
    this.configService.updateConfig({
      style: {
        colorScheme: ColorSchemeName.light
      }
    });
    this.commonService.themeControl.theme = "light"
    this.colorVariables = colorVariablesLight;//this.commonService.themeControl.theme == 'dark' ? colorVariables : colorVariablesLight;
  }

  layoutRTLChange(change: MatSlideToggleChange): void {
    this.configService.updateConfig({
      direction: change.checked ? 'rtl' : 'ltr'
    });
  }

  toolbarPositionChange(change: MatRadioChange): void {
    this.configService.updateConfig({
      toolbar: {
        fixed: change.value === 'fixed'
      }
    });
  }

  footerVisibleChange(change: MatSlideToggleChange): void {
    this.configService.updateConfig({
      footer: {
        visible: change.checked
      }
    });
  }

  footerPositionChange(change: MatRadioChange): void {
    this.configService.updateConfig({
      footer: {
        fixed: change.value === 'fixed'
      }
    });
  }

  isSelectedBorderRadius(borderRadius: CSSValue, config: VexConfig): boolean {
    return borderRadius.value === config.style.borderRadius.value && borderRadius.unit === config.style.borderRadius.unit;
  }

  selectBorderRadius(borderRadius: CSSValue): void {
    this.selectedBorderRadius = borderRadius;
    this.configService.updateConfig({
      style: {
        borderRadius: borderRadius
      }
    });
  }

  isSelectedButtonStyle(buttonStyle: CSSValue | undefined, config: VexConfig): boolean {
    if (isNil(config.style.button.borderRadius) && isNil(buttonStyle)) {
      return true;
    }

    return buttonStyle?.value === config.style.button.borderRadius?.value;
  }

  selectButtonStyle(borderRadius: CSSValue | undefined): void {
    this.configService.updateConfig({
      style: {
        button: {
          borderRadius: borderRadius
        }
      }
    });
  }

  is_menu_selected(menu) {
    return menu?.text == this.selectedMenu?.text;
  }

  isSelectedBorderRadius_Menu(menu: menuValue, index: any=0) {
    this.selectedMenu = menu;
    this.menuValues.forEach(element => {
      element.isSelect = false;
      if(element.text==menu?.text){
        element.isSelect = true;
      }
    });
    // this.menuValues[index].isSelect = true;
    if (menu?.text === "Vertical") {
      this.commonService.themeControl.mode = "vertical";
      this.configService.setConfig(VexConfigName.apollo);
    } else {
      this.commonService.themeControl.mode = "horizontal";
      this.configService.setConfig(VexConfigName.poseidon);
    }

    if (this.commonService.themeControl.theme == 'dark') {
      this.configService.updateConfig({
        style: {
          colorScheme: ColorSchemeName.dark
        }
      });
    }

    else if (this.commonService.themeControl.theme == 'light') {
      this.configService.updateConfig({
        style: {
          colorScheme: ColorSchemeName.light
        }
      });
    }
  }

  freeColor(event: any) {
    this.foreColorSelected = true;
    this.configService.updateConfig({
      style: {
        colors: {
          primary: {
            default: this.hexToRgb(event.target.value),
          }
        }
      }
    });
  }
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);
      return r + "," + g + "," + b;//return 23,14,45 -> reformat if needed
    }
    return null;
  }

  preference(type: any) {
    this.preferenceType = type;
  }

  langague(value: any) {
    this.selectedLangague = value;
  }

  closed() {
    // this.notify.confirm({
    //   title: 'Alert',
    //   message: "Are you want to save the changes?",
    //   okText: 'Ok',
    //   cancelText: 'Cancel',
    // }).then((result) => {
    //   console.log(result ? 'Agreed.' : 'Disagreed.');
    //   if (result == true) {
        this.configpanel.close();
    //   }
    // });
  }
  saveUserSettings() {
    let updateData = this.getSettingsData();
    this.updateUserSettings(updateData).then((settings: any) => {
      this.global.user.settings = settings;
      this.resetSettings();
      this.closed();
    });
  }
  getSettingsData() {
    let data: any = {};
    data.theme = {
      color: this.selectedColor,
      mode: this.theme_mode,
      langague: this.selectedLangague,
      preference: this.preferenceType,
      vertical_display: this.selectedMenu,
      border_radius: this.selectedBorderRadius

    };
    return data;
  }
  updateUserSettings(addData, is_edit = false) {
    return new Promise((resolve, reject) => {
      // if (is_edit) {
      this.userService
        .updateUserSettings(this.global.user.id, addData)
        .then((settings) => {
          resolve(settings);
        })
        .catch((err) => {
          reject(err);
        });
      // } else {
      //   this.userService
      //     .addSubUser(this.global.selectedUser.id, addData)
      //     .then((user) => {
      //       resolve(user);
      //     })
      //     .catch((err) => {
      //       reject(err);
      //     });
      // }
    });
  }
  resetSettings() {
    let settings = this.global.user.settings;
    let theme = settings?.theme;
    this.global.inputUserSettingsToUI(settings);
    if (theme) {
      this.selectedColor = theme.color;
      this.theme_mode = theme.mode;
      this.selectedLangague = theme.langague;
      this.preferenceType = theme.preference;
      this.selectedMenu = theme.vertical_display;
      this.selectedBorderRadius = theme.border_radius;

      this.selectColor(this.selectedColor);
      this.isSelectedBorderRadius_Menu(this.selectedMenu);
      this.selectBorderRadius(this.selectedBorderRadius);
      if(this.theme_mode==ColorSchemeName.dark){
        this.enableDarkMode();
      }else{
        this.disableDarkMode();
      }
      this.preference(this.preferenceType);
      this.langague(this.selectedLangague);
    }
  }
}
