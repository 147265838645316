import { Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { GlobalService } from 'src/app/common/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  items: NavigationItem[] = [];

  _openChangeSubject = new BehaviorSubject(null);
  openChange$ = this._openChangeSubject.asObservable();

  constructor(private global: GlobalService) { }

  triggerOpenChange(item: NavigationItem) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  private _valid_items;
  public get valid_items() {
    return this.items.filter((el:NavigationItem)=>{
      let is_valid = true;
      el.role = el.role?el.role:[];
      if(this.global.user?.id&&el.role?.includes('non-auth'))
        is_valid = false;
      if(this.global.user.is_subuser&&el.role?.includes('auth'))
        is_valid = false;
      if(!this.global.user.is_superuser&&el.role?.includes('admin'))
        is_valid = false;
      return is_valid;
    });
  }
  public set valid_items(value) {
    this._valid_items = value;
  }
}
