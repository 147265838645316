<div (keyup.escape)="close()" [class.show]="show$ | async" class="search">
  <button (click)="close()" class="ltr:right-12 rtl:left-12 top-12 absolute" color="primary" mat-icon-button
    type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>

  <!-- <input #searchInput (keyup.enter)="search()" [formControl]="searchCtrl" class="search-input"
    placeholder="Search..." /> -->
  <div class="mt-4 flex flex-col gap-2">
    <div class="flex flex-col sm:flex-row gap-2 sm:gap-6">

      <mat-form-field class="flex-none">
        <mat-label>Included Brands</mat-label>
        <mat-select>
          <mat-option value="Apples">Apples</mat-option>
          <mat-option value="Peaches">Peaches</mat-option>
          <mat-option value="Bananas">Bananas</mat-option>
          <mat-option value="Mango">Mango</mat-option>
          <mat-option value="Apples">Cucumber</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field class="flex-none">
        <mat-label>Included Brands</mat-label>
        <mat-select>
          <mat-option value="Apples">Apples</mat-option>
          <mat-option value="Peaches">Peaches</mat-option>
          <mat-option value="Bananas">Bananas</mat-option>
          <mat-option value="Mango">Mango</mat-option>
          <mat-option value="Apples">Cucumber</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-none">
        <mat-label>Included Brands</mat-label>
        <mat-select>
          <mat-option value="Apples">Apples</mat-option>
          <mat-option value="Peaches">Peaches</mat-option>
          <mat-option value="Bananas">Bananas</mat-option>
          <mat-option value="Mango">Mango</mat-option>
          <mat-option value="Apples">Cucumber</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-none">
        <mat-label>Included Brands</mat-label>
        <mat-select>
          <mat-option value="Apples">Apples</mat-option>
          <mat-option value="Peaches">Peaches</mat-option>
          <mat-option value="Bananas">Bananas</mat-option>
          <mat-option value="Mango">Mango</mat-option>
          <mat-option value="Apples">Cucumber</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-none">
        <mat-label>Included Brands</mat-label>
        <mat-select>
          <mat-option value="Apples">Apples</mat-option>
          <mat-option value="Peaches">Peaches</mat-option>
          <mat-option value="Bananas">Bananas</mat-option>
          <mat-option value="Mango">Mango</mat-option>
          <mat-option value="Apples">Cucumber</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

 

  <!-- <div class="search-hint">Hit enter to search</div> -->
</div>

<div (click)="close()" *ngIf="show$ | async" class="search-overlay"></div>