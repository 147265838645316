<div class="">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading">David Smith</div>
    </div>
  </div>

  <div class="dropdown-content">
    <a (click)="profile()" class="notification flex items-center" matRipple>
      <mat-icon svgIcon='mat:account_circle' ngClass='text-teal' class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">profile</div>
        <div class="notification-description"></div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-content">
    <a (click)="close()" class="notification flex items-center" matRipple>
      <mat-icon svgIcon='mat:move_to_inbox' ngClass='text-primary' class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">switch account</div>
        <div class="notification-description"></div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-content">
    <a (click)="openConfigpanel()" class="notification flex items-center" matRipple>
      <mat-icon svgIcon='mat:list_alt' ngClass='text-primary' class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">Configuration</div>
        <div class="notification-description"></div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer flex items-center justify-between">
    <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">
    </button>
    <a (click)="close()" [routerLink]="['/login']" color="primary" mat-button>Logout</a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="setStatus(status)" *ngFor="let status of statuses; trackBy: trackById" mat-menu-item>
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>


<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:business"></mat-icon>
    <span>Change Address</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:verified_user"></mat-icon>
    <span>Change Username</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:lock"></mat-icon>
    <span>Change Password</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>