<div class="vex-user-menu">
  <a (click)="profile()" class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label">Your Profile</div>
  </a>

  <div class="mx-4"></div>
  <div class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)" (click)="support()">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:contact_support"></mat-icon>
    <div class="vex-user-menu-item__label">Support</div>
  </div>

  <a (click)="changeSecurity()" class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:security"></mat-icon>
    <div class="vex-user-menu-item__label">Security</div>
  </a>
  <!-- <a (click)="changeEmail()" class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:insights"></mat-icon>
    <div class="vex-user-menu-item__label">Change User Name</div>
  </a>


  <a (click)="changePassword()" [routerLink]="['/apps/social/timeline']" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="vex-user-menu-item__label">Change Password</div>
  </a>




  <a class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:insights"></mat-icon>
    <div class="vex-user-menu-item__label">2FA</div>
    <div class="vex-user-menu-item__badge">
      <mbsc-switch>
      </mbsc-switch>
    </div>
  </a> -->


  <div class="mx-4"></div>
  <div class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)" (click)="openConfigData()">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:contact_support"></mat-icon>
    <div class="vex-user-menu-item__label">Settings</div>
  </div>

  

  <!-- <div class="vex-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
    <div class="vex-user-menu-item__label">Switch User</div>
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:chevron_right"></mat-icon>
  </div> -->



  <a (click)="close()" [routerLink]="['/login']" class="vex-user-menu-item" matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="vex-user-menu-item__label">Sign Out</div>
  </a>
</div>
<vex-center-popup #confirmhorizontal [bubblePopupValue]="bubblePopup">
  <form [formGroup]="alertFormGroup" class="alertCustomPopop">
    <div class="mbsc-align-center mbsc-padding">
      <div>
        <h2 class="mbsc-alert-title ng-star-inserted">Security</h2>
        <mbsc-input inputStyle="box" formControlName="email" labelStyle="floating" [class]="'margin-top-0'"
          placeholder="yourname@xyz.com" label="Email" type="email"
          [error]="alertFormGroup.controls['email'].errors?.required && (alertFormGroup.controls['email'].dirty || alertFormGroup.controls['email'].touched)"
          errorMessage="We need an email address to log you in">
        </mbsc-input>

        <mbsc-input inputStyle="box" formControlName="password" labelStyle="floating" [class]="'margin-bottom-0'"
          type="password" [passwordToggle]="true" placeholder="Click the eye for visibility" label="Password"
          data-password-toggle="true"
          [error]="alertFormGroup.controls['password'].errors?.required && (alertFormGroup.controls['password'].dirty || alertFormGroup.controls['password'].touched)"
          errorMessage="We need a password to log you in">
        </mbsc-input>

        <div class="flex flex-col general-status position-relative">
          <mbsc-dropdown inputStyle="box" formControlName="twofa" labelStyle="floating" label="2FA"
            class=" dropdown-lst position-absolute">
            <option value="1">2FA</option>
          </mbsc-dropdown>
          <mbsc-switch class="toggle-position toggle-position-dropdown" color="primary" formControlName="twofaSwitch">
          </mbsc-switch>
        </div>

      </div>
    </div>
  </form>
</vex-center-popup>