import { UserStatusLabelMap, UserTypeMap, RoleTypeMap, UserSubStatusMap } from "../shared/constant";

export class User {
  id: string;
  short_id:string;
  fname: string;
  lname: string;
  userPhoto: string;
  userType: string;
  phone: string;
  email: string;
  contact_email:string;
  brands_count: number;
  locations_count: number;
  monthly: string;
  total: string;
  sales_monthly: string;
  sales_total: string;
  lastLogin_time: string;
  lastLogin_date: string;
  status: string;
  labels: string;
  status_label: string;
  settings:any;
  company_name:string;
company_email:string;
company_phone:string;
  is_superuser: boolean;
  is_disabled: boolean;
  profilePicURL: string;
  image:string;
  imageSrc: string;
  status_text:string;
  sub_status_text:string;
  sub_status:string;
  total_payout_formatted:string;
  avg_payout_formatted:string;
  avg_orders:number;
  total_orders:number;
  current_month_orders:number;
  last_month_orders:number;
total_subscription_formatted:string;
current_subscription_formatted:string;
current_month_payout_formatted:string;
last_month_payout_formatted:string;
  otp:string;
  raw:any;
  type:string;
  communication_type:string;
  communication_type_list:string[]
  created:string;
  created_formatted:string;
  created_date_formatted:string;
  type_text:string;
  total_payout:any;
total_subscription:any;
current_subscription:any;
current_month_payout:any;
mobile:string;
is_email_verified:boolean;
last_login_time:string;
last_login_date:string;
mapping_id:string;
role_type_text:string;
role_type:string;
assigned_restaurants:any[]=[];
disabled_restaurants:any[]=[];
name: string;
has_payment_method:number;
parent_id:string;
is_subuser:boolean;
external_id:string;
freshdesk_restore_id:string;
is_free_trial:boolean;
tags:string[];
  // private _name: string;
  // public get name(): string {
  //   return (this.fname?this.fname:'')+(this.lname?" "+this.lname:'');
  // }
  // public set name(value: string) {
  //   this.fname = value.split(" ")[0];
  //   this.lname = value.split(" ").length>1?value.split(" ")[0]:'';
  //   this._name = value;
  // }

  constructor(data) {
    this.id = data?.id;
    this.external_id = this.id?.split("-").pop();
    this.freshdesk_restore_id = data?.freshdesk_restore_id;
    this.fname = data?.fname;
    this.lname = data?.lname;
    this.name = (data?.fname?data?.fname:'')+(data?.lname?" "+data?.lname:' ');
    this.userPhoto = data?.userPhoto;
    this.userType = data?.userType;
    this.phone = data?.phone?data?.phone:'N/A';
    this.email = data?.email;
    this.contact_email = data?.contact_email?data?.contact_email:this.email;
    this.company_name = data?.company_name;
this.company_email = data?.company_email;
this.company_phone = data?.company_phone;
    this.brands_count = data?.brands_count?data?.brands_count:undefined;
    this.locations_count = data?.locations_count?data?.locations_count:0;
    this.monthly = data?.monthly;
    this.total = data?.total;
    this.sales_monthly = data?.sales_monthly;
    this.sales_total = data?.sales_total;
    this.lastLogin_time = data?.lastLogin_time?data?.lastLogin_time:new Date().toLocaleTimeString();
    this.lastLogin_date = data?.lastLogin_date?data?.lastLogin_date:new Date().toLocaleDateString();
    this.status = data?.status;
    this.labels = data?.labels;
    this.is_superuser = data?.is_superuser;
    this.mobile = data?.mobile?data?.mobile:'N/A';
    this.type = data?.type?data.type:'0';
    this.communication_type = data?.communication_type?data.communication_type:"0|1|2|";
    this.communication_type_list = this.communication_type.split("|").join(" ").trim().split(" ");
    let type_text = UserTypeMap[this.type]?.name;

    if(this.is_superuser){
      type_text = 'Admin';
    }
    this.type_text = type_text;
    this.role_type_text = RoleTypeMap[data?.sex]?.name;
    this.is_disabled = data?.is_disabled;


    this.total_payout_formatted = data?.total_payout_formatted;
    this.total_subscription_formatted = data?.total_subscription_formatted;
    this.current_subscription_formatted = data?.current_subscription_formatted;
    this.current_month_payout_formatted = data?.current_month_payout_formatted;
    this.last_month_payout_formatted = data?.last_month_payout_formatted;
    this.total_payout = data?.total_payout;
    this.total_subscription = data?.total_subscription;
    this.current_subscription = data?.current_subscription;
    this.current_month_payout = data?.current_month_payout;
    this.avg_payout_formatted = data?.avg_payout_formatted;
    this.avg_orders = data?.avg_orders;
    this.total_orders = data?.total_orders;
    this.current_month_orders = data?.current_month_orders;
    this.last_month_orders = data?.last_month_orders;

    this.imageSrc = data?.imageSrc;
    this.image = data?.image;
    this.brands_count = data?.brands?data.brands.length:this.brands_count;
    this.locations_count = data?.restaurants?data.restaurants.length:this.locations_count;
    let disabled_restaurants = data?.restaurants?data?.restaurants:[];
    this.disabled_restaurants = disabled_restaurants.filter((e)=>{return !e.is_enabled})
    this.created=data?.created;
    this.created_date_formatted=data?.created_date_formatted;
    this.created_formatted=data?.created_formatted;
    this.is_email_verified = data?.is_email_verified;
    this.last_login_time = data?.last_login_time;
    this.last_login_time = this.last_login_time?this.last_login_time:'N/A';
    this.last_login_date = data?.last_login_date;
    this.last_login_date = this.last_login_date?this.last_login_date:'N/A';
    this.mapping_id = data?.mapping_id;
    this.assigned_restaurants=data?.assigned_restaurants?data?.assigned_restaurants:[];
    this.role_type=data?.sex;



    let last_failure_days:any = data?.last_invoice_failure?data?.last_invoice_failure:null;
    let last_failure_end_days:any = data?.last_invoice_failure_end_date?data?.last_invoice_failure_end_date:null;
    let current_date:any = new Date();

    let login_date:any = new Date(data?.last_login);
    let login_days = (current_date -login_date)/(24*60*60*1000);
    login_days = data?.last_login?login_days:null;

    if(last_failure_days){
      last_failure_days = current_date;
      let last_failure_date:any = new Date(data.last_invoice_failure);
      last_failure_days = (last_failure_days - last_failure_date)/(24*60*60*1000);
    }
    if(last_failure_end_days){
      last_failure_end_days = current_date;
      let last_failure_end_date:any = new Date(data.last_invoice_failure_end_date);
      last_failure_end_days = (current_date - last_failure_end_date)/(24*60*60*1000);
    }
    this.parent_id = data?.parent_id;
    this.is_free_trial = new Date() <= new Date(data?.first_invoiced?data?.first_invoiced:null)

    this.has_payment_method = data?.has_payment_method;

    if(this.is_disabled){
      this.sub_status = '6';
    }
    // else if(this.disabled_restaurants.length&&this.disabled_restaurants.length>=this.locations_count){
    //   this.sub_status = '7';
    // }
    else if(last_failure_days&&last_failure_end_days<=0){
      this.sub_status = '5';
    }else if(last_failure_days&&last_failure_end_days>0){
      this.sub_status = '7';
    }else if(last_failure_days&&last_failure_days<=7){
      this.sub_status = '4';
    }else if((!this.is_email_verified&&!data?.last_login)){
      this.sub_status = '2';
    }else if(!this.type||!data.user_plans?.stripe_plans?.length||!data.user_plans?.billing_cycle_type||!data?.first_invoiced){
      this.sub_status = '2';
    }else if((this.locations_count - this.disabled_restaurants.length)<=0){
      this.sub_status = '2';
    }
    // else if(this.total_subscription!=undefined&&this.total_subscription<=0){
    //   this.sub_status = '2';
    // }
    else if(this.is_free_trial){
      this.sub_status = '3';
    }else if(!this.has_payment_method){
      this.sub_status = '4';
    }else if(login_days&&login_days>30){
      this.sub_status = '1';
    }else{
      this.sub_status = '0';
    }
    this.status_text = UserSubStatusMap[this.sub_status].status;
    this.sub_status_text = UserSubStatusMap[this.sub_status].text;
    this.status_label = Object.assign({}, UserStatusLabelMap[this.status_text]);
    if(data?.last_invoice_failure==undefined){
      // this.status_text=undefined;
      // this.status_label=undefined;
    }
    this.settings = data?.settings;


    this.is_subuser = this.type=='5'?true:false;
    this.short_id = this.id?.split('-')[this.id.split('-').length-1]

    // this.short_id = this.short_id?.substring(4, 12);
    // this.shortId = this.short_id;

    let tag_user;
    if(this.type=='5'&&data.parent){
      tag_user=data.parent;
    }else{
      tag_user=this;
    }

    let tag_short_id ="#" +   tag_user.id?.split('-')[tag_user.id.split('-').length-1].substring(8, 12) + " - ";
    let company_name = tag_user.type==1?tag_user.name:tag_user.company_name;
    company_name =  company_name?tag_short_id + company_name:'';
    let tags = [];
    let tabless_type = ['1', '6'];
    if(tabless_type.includes(tag_user.type)){
      tags.push('tabless')
    }else if(!tabless_type.includes(tag_user.type)&&tag_user.type != '5'){
      tags.push(company_name)
    }else if(tag_user.type == '5'){
      tags.push(company_name)
    }
    // debugger;
    this.tags = tags;
  }

}

