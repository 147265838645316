import { Injectable } from '@angular/core';
import { User } from 'src/app/models/users.model';
import { UserService } from 'src/app/user/services/user.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../global.service';
declare var window: any;
@Injectable({
  providedIn: 'root'
})
export class FreshdeskService {

  is_loaded=false;
  constructor(private global: GlobalService,
    private userService:UserService) {
    // console.log("freshdesk serice")
    this.global.userUpdated.subscribe((v) => {
      // debugger;
      if(this.is_loaded){
        this.init(true, v);
      }

    });





    window.addEventListener?window.addEventListener("load",()=>{
      this.loadFreshChat();
    },!1):'';
  }
  loadFreshChat(){
    let i = document;
    let t = "Freshchat-js-sdk";
    // function initialize(i, t) {
      var e;
      i.getElementById(t) ?
        this.onFreshChatLoad() : ((e = i.createElement("script")).id = t, e.async = !0,
          e.src = "https://tabless-help.freshchat.com/js/widget.js", e.onload = ()=>{this.onFreshChatLoad();}, i.head.appendChild(e))
    // }
  }
  onFreshChatLoad(){

    setTimeout(() => {
      this.is_loaded=true;
      this.init(true);
    }, 500);

  }
  open(){
    window.fcWidget?.show();
    window.fcWidget?.open();
  }
  destroy() {
    window.fcWidget?.destroy();
  }
  init(destroy = false, user:User|null=null) {
    if (destroy) {
      this.destroy();
    }
    let init_data: any = {
      token: environment.freshdesk_token,
      host: environment.freshdesk_url,
      // version: '1.0',
      config: {
        headerProperty: {
          hideChatButton: true,
        },
        cssNames: {
          widget: 'fc_frame',
          open: 'fc_open',
          expanded: 'fc_expanded'
        },
        // showFAQOnOpen: true,
        hideFAQ: false,
      },
    }
    if(user&&user.tags){
      init_data['tags'] = user.tags;
    }
    if (this.global.user?.id && this.global.user?.external_id) {
      init_data['externalId'] = this.global.user?.external_id;
      init_data['restoreId'] = this.global.user?.freshdesk_restore_id?this.global.user?.freshdesk_restore_id:null;

    }
    console.log("freshdesk init data:", init_data)
    setTimeout(() => {
      window.fcWidget?.init(init_data);
      window.fcWidget?.setExternalId(init_data['externalId']);
      window.fcWidget?.hide();
      window.fcWidget?.on('user:created',(e)=>{
        let restore_id = e.data?.restoreId;
        this.updateUserRestoreId(restore_id);
      })
    }, 500);

  }
  updateUserRestoreId(restore_id){
    if(restore_id==this.global.user?.freshdesk_restore_id||!this.global.user?.id||!restore_id){
      return;
    }
    let updateData = {
      freshdesk_restore_id: restore_id
    }
    this.userService
          .updateUser(this.global.user.id, updateData)
          .then((user) => {
            // resolve(user);
            this.global.user.freshdesk_restore_id = restore_id;
          })
          .catch((err) => {
            // reject(err);
          });
  }
}
