import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { setOptions } from '@mobiscroll/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from './shared/common.service';
import { SharedService } from './services/shared.service';
import { Subscription } from 'rxjs';
import { FreshdeskService } from './common/services/freshdesk/freshdesk.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  deviceInfo: any;
  deviceStyle: any;
  searchChangeEventSubscription: Subscription;

  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService,
    private commonService: CommonService,
    private freshdeskService: FreshdeskService,
    private sharedService: SharedService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'link',
        label: 'Get Started',
        route: 'get-started',
        icon: 'mat:settings',
        role: ['non-auth']
      },
      {
        type: 'link',
        label: 'Analytics',
        route: 'analytics',
        icon: 'mat:settings'
      },
      {
        type: 'dropdown',
        label: 'Assets',
        icon: 'mat:settings',
        children: [
          {
            type: 'link',
            label: 'Locations',
            route: 'assets/location',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Brands',
            route: 'assets/brand',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Devices',
            route: 'assets/device',
            icon: 'mat:assignment'
          },
        ]
      },
      {
        type: 'link',
        label: 'Orders',
        route: 'orders',
        icon: 'mat:settings'
      },
      {
        type: 'dropdown',
        label: 'Reports',
        icon: 'mat:settings',
        children: [
          // {
          //   type: 'link',
          //   label: 'Sales',
          //   route: 'reports',
          //   icon: 'mat:assignment'
          // },
          {
            type: 'link',
            label: 'Sales',
            route: 'reports/details',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Deposits',
            route: 'reports/payout',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Disputes',
            route: '/reports/dispute',
            icon: 'mat:assignment'
          },
          // {
          //   type: 'link',
          //   label: 'Trends',
          //   route: '/reports/trend',
          //   icon: 'mat:assignment'
          // },
        ]
      },

      {
        type: 'link',
        label: 'Integration',
        route: 'integration',
        icon: 'mat:settings'
      },
      {
        type: 'dropdown',
        label: 'Menu',
        icon: 'mat:settings',
        children: [
          {
            type: 'link',
            label: 'Editor',
            route: 'menu',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Availability',
            route: 'menu/availability',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Templates',
            route: 'menu/templates',
            icon: 'mat:assignment'
          },
          {
            type: 'link',
            label: 'Reports',
            route: '/menu/reports',
            icon: 'mat:assignment'
          }
        ]
      },
      {
        type: 'link',
        label: 'User',
        route: 'user',
        icon: 'mat:settings',
        role: ['auth','admin']
      },
      {
        type: 'link',
        label: 'Email',
        route: 'mail',
        icon: 'mat:mail',
      },
    ];

    this.configService.config$.subscribe(config => {
      setOptions({
        theme: this.deviceStyle,//this.deviceInfo.os === 'iOS' ? "ios go-tabless" : "material go-tabless",
        themeVariant: config.style.colorScheme === ColorSchemeName.dark  ? 'dark' : 'light',
      });
    });
  }

  ngOnInit(): void {

    if (this.deviceInfo.os === 'iOS') {
      this.commonService.deviceType.deviceStyle = 'ios';
      this.commonService.deviceType.os = 'ios';
      this.deviceStyle = "ios";
    } else if (this.deviceInfo.os === 'Mac') {
      this.deviceStyle = "ios";
      this.commonService.deviceType.deviceStyle = 'ios';
      this.commonService.deviceType.os = 'mac';
    } else {
      this.deviceStyle = "material";
      this.commonService.deviceType.deviceStyle = 'material';
      this.commonService.deviceType.os = 'material';
    }


    this.configService.config$.subscribe(config => {
      setOptions({
        theme: this.deviceStyle,//this.deviceInfo.os === 'iOS' ? "ios go-tabless" : "material go-tabless",
        themeVariant: config.style.colorScheme === ColorSchemeName.dark  ? 'dark' : 'light',
      });
    });

    // setOptions({
    //   theme: this.deviceStyle,//this.deviceInfo.os === 'iOS' ? "ios go-tabless" : "material go-tabless",
    //   themeVariant: this.commonService.themeControl.theme,
    //   // cssClass: 'go-tabless',
    //   // touchUi: this.deviceInfo.os === 'iOS' ? true : false,
    // });

    // this.searchChangeEventSubscription = this.sharedService.searchDataChange.subscribe((data) => {
    //   console.log('data', data)
    //   setOptions({
    //     theme: this.deviceStyle,//this.deviceInfo.os === 'iOS' ? "ios go-tabless" : "material go-tabless",
    //     themeVariant: data,
    //     // cssClass: 'go-tabless',
    //     // touchUi: this.deviceInfo.os === 'iOS' ? true : false,
    //   });
    // })
  }
}
