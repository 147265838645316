import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { UtilityService } from 'src/app/common/services/utility.service';
import { DatePipe } from '@angular/common';
import { UrlService } from 'src/app/common/services/url.service';
import { GlobalService } from 'src/app/common/services/global.service';
import { RequestService } from 'src/app/common/services/request.service';
import { User } from 'src/app/models/users.model';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  allUsers:User[]=[];
  constructor(private utilService:UtilityService,
    private req:RequestService,
    private tablessURL:UrlService,
    public global:GlobalService,
    @Inject(LOCALE_ID) private locale: string,) { }

    getUser(userId:any){
      let data: any = {};
      let url = this.tablessURL.getUser;
      data.userId = userId;
      data.profileId = userId;

      // data.email=email;
      return this.req.request(url, data, 1)
        .then((success) => {
          let body: any = success;
          console.log(body);
          if (body.success) {
            let users:User[] = this.formatUserData([body.data]);
            this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
            return Promise.resolve(users[0]);
          }
          return Promise.reject(body);
        }).catch(this.handleError);
    }
    getStripePaymentMethods(userId:any){
      let data: any = {};
      let url = this.tablessURL.getStripePaymentMethods;
      data.userId = userId;
      data.profileId = userId;

      // data.email=email;
      return this.req.request(url, data, 1)
        .then((success) => {
          let body: any = success;
          console.log(body);
          if (body.success) {
            return Promise.resolve(body.data);
          }
          return Promise.reject(body);
        }).catch(this.handleError);
    }

    getStripeClientSecret(userId:any){
      let data: any = {};
      let url = this.tablessURL.getStripeClientSecret;
      data.userId = userId;
      data.profileId = userId;

      // data.email=email;
      return this.req.request(url, data, 1)
        .then((success) => {
          let body: any = success;
          console.log(body);
          if (body.success) {
            return Promise.resolve(body.data);
          }
          return Promise.reject(body);
        }).catch(this.handleError);
    }
    removeStripePaymentMethods(userId:any, methodId:any){
      let data: any = {};
      let url = this.tablessURL.removeStripePaymentMethods;
      data.userId = userId;
      data.profileId = userId;
      data.paymentMethodId = methodId;
      // data.email=email;
      return this.req.request(url, data, 1)
        .then((success) => {
          let body: any = success;
          console.log(body);
          if (body.success) {
            return Promise.resolve(body.data);
          }
          return Promise.reject(body);
        }).catch(this.handleError);
    }
  getCreatedUsersSummaryByQuery(queryData:any[]=[], last_item_id=''){
    let data: any = {};
    let url = this.tablessURL.getCreatedUsersSummaryByQuery;
    data.userId = this.global.user.id;
    data.queries = queryData;
    // data.email=email;
    if (last_item_id) {
      data.last_item_id = last_item_id;
    }
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {
          let users:User[] = this.formatUserData(body.data);
          // this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(users);
        }
        return Promise.reject(body);
      }).catch((err)=>{
        this.handleError(err);
      });
  }
  getCreatedUsers(){
    let data: any = {};
    let url = this.tablessURL.getCreatedUsers;
    data.userId = this.global.user.id;

    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {
          let users:User[] = this.formatUserData(body.data);
          this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(users);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  addUser(addData:any){
    let data: any = {};
    let url = this.tablessURL.addUser;
    data.userId = this.global.user.id;
    let keys: any = ['fname', 'mobile', 'lname', 'type', 'email', 'company_name', 'contact_email', 'company_phone', 'company_website', 'company_email'];
    keys.forEach((key: any) => {
      if (typeof addData[key] != undefined) {
        data[key] = addData[key]
      }
    });
    // data.email=email;
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {
          let users:User[] = this.formatUserData([body.data]);
          this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(users[0]);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  addSubUser(parentId, addData:any){
    let data: any = {};
    let url = this.tablessURL.addSubUser;
    data.userId = parentId;
    let keys: any = ['fname', 'mobile', 'lname', 'type', 'email', 'sex', 'assigned_restaurants'];
    keys.forEach((key: any) => {
      if (typeof addData[key] != undefined) {
        data[key] = addData[key]
      }
    });

    // data.email=email;
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {
          let users:User[] = this.formatUserData([body.data]);
          this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(users[0]);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  updateUser(userId:any, updateData:any){
    let data: any = {};
    let url = this.tablessURL.updateUser;
    Object.keys(updateData).forEach((key: any) => {
      if (typeof updateData[key] != undefined) {
        data[key] = updateData[key]
      }
    });

    data.userId = userId;

    // data.email=email;
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {

          let users:User[] = this.formatUserData([body.data]);
          this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(users[0]);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  getUserSettings(userId:any){
    let data: any = {};
    let url = this.tablessURL.getUserSettings;
    data.userId = userId;

    // data.email=email;
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {

          // let users:User[] = this.formatUserData([body.data]);
          // this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(body.data);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  updateUserSettings(userId:any, updateData:any){
    let data: any = {};
    let url = this.tablessURL.updateUserSettings;
    Object.keys(updateData).forEach((key: any) => {
      if (typeof updateData[key] != undefined) {
        data[key] = updateData[key]
      }
    });

    data.userId = userId;

    // data.email=email;
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {

          // let users:User[] = this.formatUserData([body.data]);
          // this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(body.data);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  disableUser(userId:any){
    let data: any = {};
    let url = this.tablessURL.disableUser;
    data.userId = userId;

    // data.email=email;
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {

          let users:User[] = this.formatUserData([body.data]);
          this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(users[0]);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  enableUser(userId:any){
    let data: any = {};
    let url = this.tablessURL.enableUser;
    data.userId = userId;

    // data.email=email;
    return this.req.request(url, data, 1)
      .then((success) => {
        let body: any = success;
        console.log(body);
        if (body.success) {

          let users:User[] = this.formatUserData([body.data]);
          this.utilService.updateOrInsertObjsToArray(this.allUsers, users);
          return Promise.resolve(users[0]);
        }
        return Promise.reject(body);
      }).catch(this.handleError);
  }
  monthDiff(d2, d1){
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += (d2.getMonth()+1);
    return months <= 0 ? 1 : months;
}
  formatUserData(users:any[]){
    let formatted_users:User[] = [];
    users.forEach((user)=>{
      user.imageSrc = this.utilService.formatImageSrc(user.image, "assets/img/avatars/Avatar-1.jpg", true);
      user.created_formatted = new DatePipe(this.locale).transform(user.created, 'dd MMM h:mm a');
      user.created_date_formatted = new DatePipe(this.locale).transform(user.created, 'dd/MM/yyyy');

      user.total_months = this.monthDiff(new Date(), new Date(user.created));
      if(user.total_months<2){
        user.total_months = 2;
      }
      user.last_login_date = new DatePipe(this.locale).transform(user.last_login, 'dd/MM/yyyy');
      user.last_login_time = new DatePipe(this.locale).transform(user.last_login, 'h:mm:ss a');

      user.total_payout_formatted=user.total_payout!=undefined?this.utilService.getReportTableRenderedText(user.total_payout):undefined;
      user.avg_payout_formatted=user.total_payout!=undefined?this.utilService.getReportTableRenderedText(user.total_payout/user.total_months):undefined;


      user.avg_orders=(user.total_orders?user.total_orders:0)/user.total_months;
      user.avg_orders = parseInt(user.avg_orders);
      user.total_subscription_formatted=user.total_subscription!=undefined?this.utilService.getReportTableRenderedText(user.total_subscription):undefined;
      user.current_subscription_formatted=user.current_subscription!=undefined?this.utilService.getReportTableRenderedText(user.current_subscription):undefined;
      user.current_month_payout_formatted=user.current_month_payout!=undefined?this.utilService.getReportTableRenderedText(user.current_month_payout):undefined;
      user.last_month_payout_formatted=user.last_month_payout!=undefined?this.utilService.getReportTableRenderedText(user.last_month_payout):undefined;
      formatted_users.push(new User(user));
    });
    return formatted_users;
  }
  private handleError (error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
    debugger;
    let errMsg: string;
    if (error instanceof Response) {
      const body:any = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.error ? error.error : error.toString();
    }
    console.error(errMsg);
    return Promise.reject(errMsg);
  }

  showSuccess(){
    console.log("enter success")
    // toastr.options.progressBar = true;
		// 	toastr.options.timeOut = 3000;
		// 	toastr.options.extendedTimeOut = 100;
		// 	toastr.options.preventDuplicates = true;
		// 	toastr.success('Profile Updated Successfully', 'success');
  }
}
