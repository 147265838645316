import { Injectable } from '@angular/core';
import { UrlService } from './url.service';
import * as moment from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private tablessURL: UrlService) { }
  private convertValueToThousandSeperator(value: any) {
    value = value.toFixed(2);
    let intValue = value.split(".")[0];
    let precision = value.split(".")[1];
    intValue = intValue.split("").reverse().join("");
    intValue = intValue.match(/.{1,3}/g).join(",");
    intValue = intValue.split("").reverse().join("");
    return intValue + "." + precision;
  }
  getShiftDataFromTime(time: string) {
    let parts: any = time.match(/(\d+)\:(\d+)\s(\w+)/);
    if (!parts) {
      parts = time.match(/(\d+)\:(\d+)(\w+)/);
    }
    if (!parts) {
      return null;
    }
    let hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) % 12 : parseInt(parts[1], 10) % 12 + 12;
    let minutes = parseInt(parts[2], 10);

    return {
      hours: hours,
      minutes: minutes,
      seconds: 0
    }
  }

  getShiftByWeekDay(hours: any, weekday: any) {
    weekday = weekday % 7;
    let map: any = {
      0: "sunday",
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
    }
    if (hours) {
      return hours[map[weekday]];
    } else {
      return null;
    }

  }
  setShiftByWeekDay(shift: any, hours: any, weekday: any) {
    weekday = weekday % 7;
    let map: any = {
      0: "sunday",
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
    }
    hours[map[weekday]] = shift;
  }
  formatHoursData(hours: any) {
    let shifts: any[] = []
    let map: any = {
      0: "sunday",
      1: "monday",
      2: "tuesday",
      3: "wednesday",
      4: "thursday",
      5: "friday",
      6: "saturday",
    }
    Object.keys(map).forEach((key) => {
      if(hours[map[key]]){

      }else{
        hours[map[key]] = {}  ;
      }
      hours[map[key]].weekday = key
      shifts.push(hours[map[key]]);
    });
    hours.shifts = shifts;
    shifts.forEach((shift) => {
      // let start_date = new Date(shift.start_time);
      shift = this.formatHoursToShift(shift);

      this.addTodayShiftTime(shift);
    });

    this.findAndUpdateTodayShift(hours);
    // shifts.forEach((shift)=>{
    hours.type = hours.type?hours.type:0;
    // });
  }
  toLocationTZ(dateStr, location){
    let timezone = location.timezone;
    if(!timezone){
      return dateStr;
    }
    let tzdate = moment(dateStr);
    return tzdate.tz(timezone);
  }
  private findAndUpdateTodayShift(hours: any) {
    let weekday = new Date().getDay();
    let today = this.getShiftByWeekDay(hours, weekday);


    if (today.today_start > new Date()) {
      weekday = weekday - 1
      today = this.getShiftByWeekDay(hours, weekday);
    } else {
      weekday = weekday + 1
      let next_day = this.getShiftByWeekDay(hours, weekday);
      if (next_day < new Date()) {
        today = next_day
      } else {
        weekday = weekday - 1
      }
    }
    hours.shifts.forEach((shift: any) => {
      shift.is_today = false;
    });
    if(today){
      today.is_today = true;
    }

    return today;
  }
  private addTodayShiftTime(shift: any) {
    let timezone = shift.timezone || "Atlantic/Azores";
    let start_hours = shift.start_time.hours || 0;
    let start_minutes = shift.start_time.minutes || 0;
    let start_seconds = shift.start_time.seconds || 0;
    let start_dt = moment(new Date()).tz(timezone);
    start_dt.hours(start_hours);
    start_dt.minutes(start_minutes);
    start_dt.seconds(start_seconds);

    let end_hours = shift.end_time.hours || 0;
    let end_minutes = shift.end_time.minutes || 0;
    let end_seconds = shift.end_time.seconds || 0;
    let end_dt = moment(new Date()).tz(timezone);
    end_dt.hours(end_hours);
    end_dt.minutes(end_minutes);
    end_dt.seconds(end_seconds);
    if (end_dt <= start_dt) {
      end_dt = end_dt.add(1, 'day');
      shift.is_next_day_end = true;
    }
    shift.today_start = start_dt.toDate();
    shift.today_end = end_dt.toDate();
    return shift;
  }
  formatHoursToShift(hours, do_shift=false) {

    if (hours.times?.length > 0) {
      hours.times.forEach((time: any, index: any) => {
        let start = time.start;
        let end = time.end;
        if (!start) {
          start = {
            hours: 0,
            minutes: 0,
            seconds: 0
          }
        }
        if (!end) {
          end = {
            hours: 0,
            minutes: 0,
            seconds: 0
          }
        }
        let start_hours = start.hours % 12 == 0 ? 12 : start.hours % 12;
        let start_minutes = start.minutes % 60;
        let start_seconds = start.seconds % 60;
        let start_dayType = start.hours >= 12 ? 'PM' : 'AM';
        // let end_date = new Date(shift.end_time);
        let end_hours = end.hours % 12 == 0 ? 12 : end.hours % 12;
        let end_minutes = end.minutes % 60;
        let end_seconds = end.seconds % 60;
        let end_dayType = end.hours >= 12 ? 'PM' : 'AM';

        time.formatted_start_time = start_hours.toString() + ':' + (start_minutes.toString().length < 2 ? '0' + start_minutes.toString() : start_minutes.toString()) + ' ' + start_dayType;
        time.formatted_end_time = end_hours.toString() + ':' + (end_minutes.toString().length < 2 ? '0' + end_minutes.toString() : end_minutes.toString()) + ' ' + end_dayType;
        time.value = [time.formatted_start_time, time.formatted_end_time];
      });
      hours.times = [...hours.times]
      hours.start_time = hours.times[0].start;
      // shift.end_time = shift.times[shift.times.length - 1].end;
      hours.end_time = hours.times[0].end;


      hours.formatted_start_time = hours.times[0].formatted_start_time;
      // shift.formatted_end_time = shift.times[shift.times.length - 1].formatted_end_time;
      hours.formatted_end_time = hours.times[0].formatted_end_time;
      hours.value = [hours.formatted_start_time, hours.formatted_end_time];
    }

    if (!hours.start_time) {
      hours.start_time = {
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
    if (!hours.end_time) {
      hours.end_time = {
        hours: 0,
        minutes: 0,
        seconds: 0
      }

    }
    if(do_shift){
      hours?.times?.shift();
    }

    return hours;
  }
  formatShiftHours(timing) {
    if (!timing) {
      return null;
    }
    let shift: any = {};
    shift.times = [];
    shift.is_closed = timing.is_closed && !timing.fullHours;

    shift.type = timing.fullHours ? 1 : 0;
    let time: any = {};
    if (shift.is_closed) {
      time['start'] = this.getShiftDataFromTime("08:00 AM");
      time['end'] = this.getShiftDataFromTime("08:00 PM");

      time['formatted_start'] = "08:00 AM";
      time['formatted_end'] = "08:00 PM";
    } else if (shift.type == 1) {
      time['start'] = this.getShiftDataFromTime("08:00 AM");
      time['end'] = this.getShiftDataFromTime("08:00 AM");

      time['formatted_start'] = "08:00 AM";
      time['formatted_end'] = "08:00 AM";
    } else {
      time['start'] = this.getShiftDataFromTime(timing.value[0]);
      time['end'] = this.getShiftDataFromTime(timing.value[1]);
      time['formatted_start'] = timing.value[0];
      time['formatted_end'] = timing.value[1];

    }
    shift.times.push(time);
    if (timing.times && timing.times.length > 0) {
      timing.times.forEach((opening: any, opening_index: any) => {
        let time: any = {};
        time['start'] = this.getShiftDataFromTime(opening.value[0]);
        time['end'] = this.getShiftDataFromTime(opening.value[1]);
        time['formatted_start'] = opening.value[0];
        time['formatted_end'] = opening.value[1];
        shift.times.push(time);
      });

    }
    return shift;
  }
  getReportTableRenderedText(value: any) {
    if (typeof (value) == "undefined") {
      // return "<span class=light-lable> N/A </span>";
      return undefined;
    } else if (typeof (value) != "number") {
      if (parseFloat(value) != NaN) {
        value = parseFloat(value)
      } else {
        return value;
      }
    }
    if (value < 0) {
      value = this.convertValueToThousandSeperator(value * -1);
      return "($" + value + ")";
    } else if (value > 0) {
      value = this.convertValueToThousandSeperator(value);
      return "$" + value;
    } else {
      // return "<span class=light-lable> N/A </span>";
      return "N/A"
    }
  }
  formatImageSrc(src: any, defaultSrc: any = 'assets/img/avatars/Avatar-1.jpg', is_thumbnail = false) {
    if (src) {
      if (src.startsWith("assets/")) {
        return src;
      } else {
        if (is_thumbnail) {
          return this.tablessURL.base + "/static/thumbnails/" + src;
        } else {
          return this.tablessURL.base + "/static/images/" + src;
        }

      }
    } else {
      return defaultSrc;
    }
  }
  updateOrInsertObjsToArray(arr: any[], objs: any[], do_unshift: boolean = false, insert_only=false, only_non_exist_keys=false) {
    objs.forEach((newObj) => {
      let oldObjs = arr.filter((item: any) => { return item.id === newObj.id });
      if (oldObjs.length == 0) {
        if (do_unshift) {
          arr.unshift(newObj);
        } else {
          arr.push(newObj);
        }
      } else {
        if (Array.isArray(oldObjs)) {
          oldObjs = oldObjs[0];
        }
        if (oldObjs&&!insert_only) {
          this.updateDataToExistingObj(oldObjs, newObj, false, only_non_exist_keys);
        }

      }
    });
  }

  monthDiff(d1: Date, d2: Date) {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 1 : months;
  }
  updateDataToExistingObj(oldObj: any, newObj: any, is_keys_from_old = false, only_non_exist_keys=false) {
    if (oldObj === undefined || oldObj == null) {
      oldObj = {};
    }
    if (newObj === undefined || newObj == null) {
      return;
    }
    let keys: any[] = [];
    if (is_keys_from_old) {
      keys = Object.keys(oldObj);
    } else {
      keys = Object.keys(newObj);
    }
    keys.forEach(key => {
      if (this.isObject(newObj[key]) && oldObj[key] != null) {
        this.updateDataToExistingObj(oldObj[key], newObj[key]);
      } else {
        if (oldObj[key] !== newObj[key] && newObj[key] !== undefined) {
          if(only_non_exist_keys&&oldObj[key]==undefined){
            oldObj[key] = newObj[key];
          }else if(!only_non_exist_keys){
            oldObj[key] = newObj[key];
          }

        }
      }
    });
  }
  isObject(obj: any) {
    if (typeof obj == "object" && obj != null && obj != undefined) {
      return true;
    } else {
      return false;
    }
  }
  formatToStrOrNull(value: any) {
    let formatted_value: any = null;
    if (value && value.toString()) {
      formatted_value = value.toString();
    } else {
      if (!value) {
        formatted_value = null;
      } else {
        formatted_value = value;
      }
    }
    return formatted_value;
  }
}
